<script setup lang="ts">
import { ref, onMounted, provide, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { DataSource, Item, Role, Visibility } from "@/types";
import {
  getDataSourceById,
  getDatasourceDocuments,
  getUserDataSourceRole,
  updateDataSource,
} from "@/services/data_source";
import { useTenantStore } from "@/stores/tenant";
import SharePointUpload from "@/components/SharePointUpload.vue";
import DataSourceDelete from "@/components/DataSourceDelete.vue";
import { CardTitle, CardDescription } from "@/components/ui/card";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  Loader2,
  ChevronLeft,
  Eye,
  EyeOff,
  Lightbulb,
  Pen,
  X,
  Save,
} from "lucide-vue-next";
import LocalUpload from "@/components/LocalUpload.vue";
import { Textarea } from "@/components/ui/textarea";
import { getUserIndexationQuotas } from "@/services/quotas";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import UrlUpload from "@/components/UrlUpload.vue";
import { useConfigCatStore } from "@/stores/configCat";
import { columns } from "@/components/Datatables/IndexedItemsColumns";
import { PaginationState } from "@/components/Datatables/Datatable.vue";
import Datatable from "@/components/Datatables/Datatable.vue";
import { Progress } from "@/components/ui/progress";

const configCatStore = useConfigCatStore();
const route = useRoute();
const router = useRouter();
const dataSource = ref<DataSource | null>(null);
const tenantStore = useTenantStore();
const tenantId = ref<string>(tenantStore.tenantId || "");
const showVisibilityUpdateAlert = ref(false);
const skipWatch = ref(false);
const previousVisibility = ref<string | undefined>(undefined);
const maxQuota = ref(1);
const usedQuota = ref(0);
const usedPercentage = computed(() =>
  Math.round((usedQuota.value / maxQuota.value) * 100)
);

const displayDescriptionInput = ref(false);
const newDescription = ref<string | undefined>("");

const loadingDataSource = ref(true);
const loadingUserDataSourceRole = ref(true);
const visibilityValues = Object.values(Visibility);
const selectedVisibility = ref<string | undefined>(undefined);
const isAdmin = tenantStore.tenantAdmin;

const userDataSourceRole = ref<string>(Role.NONE);

const documents = ref<Item[]>([]);
const documents_count = ref<number>(0);
const isInitialLoadingDocuments = ref<boolean>(true);
const isRefreshingDocuments = ref<boolean>(false);
const pagination = ref<PaginationState>({
  pageIndex: 0,
  pageSize: 10,
});

const loadDataSource = async () => {
  const dataSourceId = route.params.id as string;
  dataSource.value = await getDataSourceById(dataSourceId, tenantId.value);
  if (dataSource.value) {
    loadingDataSource.value = false;
    selectedVisibility.value = dataSource.value.visibility;
    previousVisibility.value = dataSource.value.visibility;
    newDescription.value = dataSource.value.description;
  }
};

const loadUserDataSourceRole = async () => {
  const dataSourceId = route.params.id as string;
  userDataSourceRole.value = await getUserDataSourceRole(
    dataSourceId,
    tenantId.value
  );
  loadingUserDataSourceRole.value = false;
};

const goBack = () => {
  router.push({ name: "Data sources" });
};

const getDocuments = async () => {
  const dataSourceId = Number(route.params.id);

  if (!isInitialLoadingDocuments.value) {
    isRefreshingDocuments.value = true;
  }

  try {
    const result = await getDatasourceDocuments({
      dataSourceIds: [dataSourceId],
      tenantId: tenantId.value,
      page: pagination.value.pageIndex + 1,
      pageSize: pagination.value.pageSize,
    });
    documents.value = result["items"];
    documents_count.value = result["count"];
  } catch (e) {
    console.error(`Error while fetching documents: ${e}`);
  } finally {
    isInitialLoadingDocuments.value = false;
    isRefreshingDocuments.value = false;
  }
};

const loading = computed(() => {
  return loadingDataSource.value || loadingUserDataSourceRole.value;
});

onMounted(async () => {
  loadDataSource();
  getDocuments();
  loadUserDataSourceRole();
  if (tenantId.value) {
    const reponse = await getUserIndexationQuotas(tenantId.value);
    maxQuota.value = reponse.maxQuota;
    usedQuota.value = reponse.usedQuota;
  }
});

watch(
  () => pagination.value,
  (newVal, oldVal) => {
    if (
      newVal.pageIndex !== oldVal?.pageIndex ||
      newVal.pageSize !== oldVal?.pageSize
    ) {
      getDocuments();
    }
  },
  { deep: true }
);

watch(selectedVisibility, async (newVisibility, oldVisibility) => {
  if (skipWatch.value) {
    skipWatch.value = false;
    return;
  }
  if (
    oldVisibility &&
    newVisibility &&
    newVisibility !== oldVisibility &&
    dataSource.value
  ) {
    previousVisibility.value = oldVisibility;
    showVisibilityUpdateAlert.value = true;
  }
});

async function updateDataSourceVisibility() {
  if (dataSource.value && selectedVisibility.value) {
    await updateDataSource(
      dataSource.value.name,
      dataSource.value.id,
      tenantId.value,
      selectedVisibility.value
    );
    previousVisibility.value = selectedVisibility.value;
    showVisibilityUpdateAlert.value = false;
  }
}

function cancelDataSourceVisibilityUpdate() {
  skipWatch.value = true;
  selectedVisibility.value = previousVisibility.value;
  showVisibilityUpdateAlert.value = false;
}

async function updateDescription() {
  if (dataSource.value && newDescription.value) {
    await updateDataSource(
      dataSource.value.name,
      dataSource.value.id,
      tenantId.value,
      undefined,
      newDescription.value
    );
    displayDescriptionInput.value = false;
    dataSource.value.description = newDescription.value;
  }
}

provide("dataSource", dataSource);
</script>

<template>
  <div
    class="max-h-screen h-screen flex flex-col overflow-y-auto bg-primary10 p-8"
  >
    <div>
      <div class="flex items-center">
        <button @click="goBack" class="mr-4">
          <ChevronLeft class="h-6 w-6 text-gray-600" />
        </button>
        <div>
          <CardTitle> Manage Data Source </CardTitle>
          <CardDescription v-if="!loading">
            {{ dataSource?.name }}
          </CardDescription>
        </div>
      </div>
    </div>
    <div v-if="loading">
      <Loader2 class="animate-spin h-5 w-5" />
    </div>
    <div v-else class="flex flex-col gap-4 mt-4">
      <div class="general_infos">
        <div class="flex gap-2 items-center">
          <h1 class="font-semibold text-lg">Description</h1>
          <Button
            v-if="userDataSourceRole == Role.OWNER || isAdmin"
            @click="displayDescriptionInput = !displayDescriptionInput"
            size="sm"
            class="hover:bg-gray-200 bg-white w-6 h-6 rounded-full p-0 relative group"
          >
            <Pen
              v-if="!displayDescriptionInput"
              class="w-3 h-3 text-gray-800"
            />
            <X v-if="displayDescriptionInput" class="w-3 h-3 text-gray-800" />
            <span
              class="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 opacity-0 group-hover:opacity-100 group-hover:bg-gray-400 group-hover:text-white text-xs rounded p-1 transition-opacity duration-300 whitespace-nowrap pointer-events-none z-50"
            >
              Edit
            </span>
          </Button>
        </div>
        <p
          v-if="dataSource?.description && !displayDescriptionInput"
          class="pl-2 text-sm text-gray-500 w-1/2"
        >
          {{ dataSource?.description }}
        </p>
        <form
          v-if="displayDescriptionInput"
          class="w-1/2 p-2 flex items-center gap-2"
          @submit.prevent="updateDescription"
        >
          <FormField name="description" class="flex-grow">
            <FormItem class="w-full flex-grow">
              <FormControl class="w-full flex">
                <Textarea
                  class="resize-none w-full flex-grow"
                  v-model="newDescription"
                  @keydown.enter.prevent="updateDescription"
                  maxlength="200"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <Button
            type="submit"
            size="sm"
            class="ml-auto hover:bg-gray-200 bg-white w-8 h-8 rounded-full p-0 relative group"
          >
            <Save
              v-if="displayDescriptionInput"
              class="w-4 h-4 text-gray-800"
            />
            <span
              class="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 opacity-0 group-hover:opacity-100 group-hover:bg-gray-400 group-hover:text-white text-xs rounded p-1 transition-opacity duration-300 whitespace-nowrap pointer-events-none z-50"
            >
              Save
            </span>
          </Button>
        </form>
      </div>
      <div class="add_documents">
        <h1 class="font-semibold text-lg">Indexing</h1>
        <div
          v-if="userDataSourceRole == Role.OWNER || isAdmin"
          class="index_documents_mode flex"
        >
          <SharePointUpload />
          <LocalUpload />
          <UrlUpload v-if="configCatStore.webUrlUpload" />
        </div>
        <div v-if="configCatStore.indexingQuotas">
          <div class="p-2 text-sm text-gray-500">
            <p>
              <strong>Quotas</strong>: {{ usedQuota }} / {{ maxQuota }} pages
              indexed.
            </p>
          </div>
          <div class="flex items-center">
            <Progress :model-value="usedPercentage" class="ml-2 w-1/5 h-2" />
            <p class="pl-2 text-sm text-gray-500 font-semibold">
              {{ usedPercentage }} %
            </p>
          </div>
        </div>
        <h1 class="font-semibold text-lg mt-2 mb-2">Documents</h1>
        <Loader v-if="isInitialLoadingDocuments" class="animate-spin h-5 w-5" />
        <Datatable
          v-else
          v-model:pagination="pagination"
          :data="documents"
          :count="documents_count"
          :columns="columns"
          :loading="isRefreshingDocuments"
        />
      </div>
      <div class="visibility">
        <h1 class="font-semibold text-lg">Visibility</h1>
        <div
          v-if="userDataSourceRole == Role.OWNER && isAdmin"
          class="flex !items-center pl-2 pt-2 gap-2"
        >
          <Select v-model="selectedVisibility" v-if="selectedVisibility">
            <SelectTrigger class="w-auto">
              <SelectValue class="!flex !items-center gap-2 p-2">
                <Eye
                  v-if="selectedVisibility === Visibility.PUBLIC"
                  class="text-primary"
                />
                <EyeOff v-else class="text-primary" />
                <p class="text-black">
                  {{
                    selectedVisibility.charAt(0).toUpperCase() +
                    selectedVisibility.slice(1)
                  }}
                </p>
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem
                v-for="value in visibilityValues"
                :key="value"
                :value="value"
              >
                {{ value.charAt(0).toUpperCase() + value.slice(1) }}
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div v-else-if="selectedVisibility">
          <p class="pl-2 text-sm text-gray-500">
            {{
              selectedVisibility.charAt(0).toUpperCase() +
              selectedVisibility.slice(1)
            }}
          </p>
        </div>
        <div class="flex !items-center gap-1 p-2 pb-0">
          <Lightbulb class="w-4 h-4 text-yellow-500 flex-none" />
          <p
            v-if="selectedVisibility === Visibility.PRIVATE"
            class="text-xs text-gray-500"
          >
            Only you can see and question this data source in the chat.
          </p>
          <p
            v-else-if="selectedVisibility === Visibility.PUBLIC"
            class="text-xs text-gray-500"
          >
            All your organization members can see and question this data source
            in the chat.
          </p>
        </div>
      </div>
      <div class="permissions">
        <h1 class="font-semibold text-lg">Permissions</h1>
        <p
          v-if="userDataSourceRole === Role.OWNER || isAdmin"
          class="pl-2 text-sm text-gray-500"
        >
          <strong
            >{{
              userDataSourceRole === Role.OWNER ? Role.OWNER : "Admin"
            }}: </strong
          >you can edit this data source, change its content, and question it in
          the chat.
        </p>
        <p v-else class="pl-2 text-sm text-gray-500">
          You can only question this data source in the chat.
        </p>
      </div>
      <DataSourceDelete
        v-if="userDataSourceRole == Role.OWNER"
        :dataSource="dataSource"
      />
    </div>
  </div>
  <AlertDialog
    v-model:open="showVisibilityUpdateAlert"
    class="delete_datasource_dialog"
  >
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle
          >Change data source visibility to
          <span class="text-primary">{{ selectedVisibility }}</span
          >?</AlertDialogTitle
        >
        <AlertDialogDescription>
          <p v-if="selectedVisibility === Visibility.PRIVATE">
            Only you will be able to see and question this data source in the
            chat.
          </p>
          <p v-else-if="selectedVisibility === Visibility.PUBLIC">
            All your organization members will be able to see and question this
            data source in the chat.
          </p>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel @click="cancelDataSourceVisibilityUpdate"
          >Cancel</AlertDialogCancel
        >
        <AlertDialogAction @click="updateDataSourceVisibility"
          >Confirm</AlertDialogAction
        >
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
