<script setup lang="ts">
import { useShepherd } from "vue-shepherd";
import { useTenantStore } from "@/stores/tenant";
import { upsertOnboardingTour } from "@/services/onboarding_tour";

const { tenantId } = useTenantStore();

const datasourcesTour = useShepherd({
  useModalOverlay: true,
  exitOnEsc: false,
  defaultStepOptions: {
    scrollTo: true,
    canClickTarget: false,
    modalOverlayOpeningRadius: 4,
    highlightClass: "shepherd_highlight",
    cancelIcon: {
      enabled: true,
    },
  },
});

const emit = defineEmits(["update:isDialogOpen"]);

datasourcesTour.addSteps([
  {
    id: "addSourceButton",
    title: "Initiate datasource creation",
    text: 'Click on the "+" button to start the process of adding a new data source',
    attachTo: {
      element: "#add_source_button",
      on: "bottom",
    },
    beforeShowPromise: async () => {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve("element is mounted");
        }, 100);
      });
    },
    buttons: [
      {
        text: "Next",
        action: () => {
          emit("update:isDialogOpen", true);
          datasourcesTour.next();
        },
      },
    ],
    modalOverlayOpeningRadius: 10,
  },
  {
    id: "datasource_name_input",
    title: "Provide Essential Details",
    text: "Assign a clear and descriptive name to your new data source. This will help you easily identify it later",
    attachTo: {
      element: ".alertDialog_name_input",
      on: "bottom",
    },
    beforeShowPromise: async () => {
      document.body.classList.add("custom_body_style");
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve("element is mounted");
        }, 100);
      });
    },
    buttons: [
      {
        text: "Previous",
        action: datasourcesTour.back,
      },
      {
        text: "Next",
        action: datasourcesTour.next,
      },
    ],
  },
  {
    id: "datasource_description_input",
    title: "Provide Essential Details",
    text: "Provide an optional description to further clarify the purpose or content of the data source",
    attachTo: {
      element: ".alertDialog_description_input",
      on: "bottom",
    },
    buttons: [
      {
        text: "Previous",
        action: datasourcesTour.back,
      },
      {
        text: "Next",
        action: datasourcesTour.next,
      },
    ],
  },
  {
    id: "datasource_create_button",
    title: "Confirm and Create",
    text: 'Once you\'ve filled in all the necessary details, click the "Create" button to finalize the creation of your new data source',
    attachTo: {
      element: ".alertDialog_create_button",
      on: "bottom",
    },
    buttons: [
      {
        text: "Previous",
        action: datasourcesTour.back,
      },
      {
        text: "Finish",
        action: async () => {
          datasourcesTour.complete();
          if (tenantId) {
            await upsertOnboardingTour({
              tenantId,
              onboardingType: "datasource_creation",
              status: "done",
            });
          }
          document.body.classList.remove("custom_body_style");
          emit("update:isDialogOpen", false);
        },
      },
    ],
  },
]);

datasourcesTour.start();

datasourcesTour.on("cancel", async () => {
  datasourcesTour.complete();
  if (tenantId) {
    await upsertOnboardingTour({
      tenantId,
      onboardingType: "datasource_creation",
      status: "skipped",
    });
  }
});
</script>

<style>
.custom_body_style {
  pointer-events: auto !important;
}
</style>
