import {
  LogLevel,
  PublicClientApplication,
  AccountInfo,
} from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
async function getMsalConfig(clientId: string, azureTenantId: string) {
  return {
    auth: {
      clientId: clientId,
      authority: `https://login.microsoftonline.com/${azureTenantId}`,
      // clientId: "2a0bce30-ebec-43a4-bd33-70ab1967ce53",
      // authority: "https://login.microsoftonline.com/3761ab19-9fbc-41ce-a267-878aea23b973",
    },
    cache: {
      // cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (
          level: LogLevel,
          message: string,
          containsPii: boolean,
        ) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      },
    },
  };
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
async function getLoginRequest(isSitesReadAll: boolean) {
  const scopes = ["User.Read"];
  if (isSitesReadAll) {
    scopes.push("Sites.Read.All");
  } else {
    scopes.push("Sites.Selected");
  }
  return {
    scopes: scopes,
  };
}
async function selectAccount(
  msalApp: PublicClientApplication,
  isSitesReadAll: boolean,
) {
  const currentAccounts = msalApp.getAllAccounts();
  if (!currentAccounts || currentAccounts.length < 1) {
    console.log("No accounts detected, login.");
    const loginRequest = await getLoginRequest(isSitesReadAll);
    msalApp.loginRedirect(loginRequest);
    return;
  }
  return currentAccounts[0];
}

async function getMsalApp(clientId: string, azureTenantId: string) {
  const msalConfig = await getMsalConfig(clientId, azureTenantId);
  const msalApp = new PublicClientApplication(msalConfig);
  await msalApp.initialize();
  return msalApp;
}

export async function handleMicrosoftConnection(
  clientId: string,
  azureTenantId: string,
  isSitesReadAll: boolean,
) {
  const msalApp = await getMsalApp(clientId, azureTenantId);

  let response = await msalApp.handleRedirectPromise();

  if (response !== null) {
    const currentAccounts = msalApp.getAllAccounts();
    return currentAccounts[0];
  } else {
    return await selectAccount(msalApp, isSitesReadAll);
  }
}

export async function getMicrosoftToken(
  account: AccountInfo,
  clientId: string,
  azureTenantId: string,
  isSitesReadAll: boolean,
) {
  const msalApp = await getMsalApp(clientId, azureTenantId);

  const loginRequest = await getLoginRequest(isSitesReadAll);
  const tokenResponse = await msalApp.acquireTokenSilent({
    account: account,
    scopes: loginRequest.scopes,
  });
  return tokenResponse.accessToken;
}
