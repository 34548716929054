<script setup lang="ts">
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { getSkills, setUserSkill } from "@/services/skill";
import { computed, onMounted, ref, watch } from "vue";
import { useTenantStore } from "@/stores/tenant";
import { Loader2, Brain, CircleAlert } from "lucide-vue-next";
import { Skill, Skills } from "@/types";
import { useRoute } from "vue-router";
import { useToolbarStore } from "@/stores/toolbarStore";
import { getDataSources, getSelectedDataSources } from "@/services/data_source";
import { DataSource } from "@/types";

const tenantStore = useTenantStore();
const selectedTenant = ref<string | undefined>(undefined);
const toolbarStore = useToolbarStore();
const datasources = ref<DataSource[]>([]);

const skills = ref<Skill[]>([]);
const loadingSkills = ref(true);
const selectedSkillName = ref<string | undefined>(undefined); // Lier à la chaîne
const route = useRoute();

onMounted(async () => {
  if (!tenantStore.tenantId) {
    return;
  }
  selectedTenant.value = tenantStore.tenantId;
  await loadSkills();
});

const isSkillDisabled = computed(() => datasources.value.length === 0); // Computed property to check if datasources is empty

async function loadSkills() {
  if (!tenantStore.tenantId) {
    return;
  }
  datasources.value = await getDataSources(tenantStore.tenantId);

  loadingSkills.value = true;

  const response = await getSkills(tenantStore.tenantId);
  skills.value = response.skills;
  selectedSkillName.value = skills.value[0]?.name; // Sélectionne le premier skill par défaut

  loadingSkills.value = false;
}

watch(selectedSkillName, (newSkillName) => {
  if (newSkillName) {
    const selectedSkill = skills.value.find(
      (skill) => skill.name === newSkillName
    );
    if (selectedSkill) {
      if (toolbarStore.selectedSkill !== selectedSkill.name) {
        toolbarStore.selectedSkill = selectedSkill.name as Skills;
      }
    }
  }
});

watch(
  () => toolbarStore.selectedSkill,
  async (newSkillName) => {
    if (!newSkillName || !tenantStore.tenantId) {
      return;
    }
    const chat_session_id = route.params.session_id as string;
    loadingSkills.value = true;
    await setUserSkill(
      tenantStore.tenantId as string,
      newSkillName as string,
      chat_session_id
    );

    const userDataSources = await getSelectedDataSources(
      tenantStore.tenantId,
      chat_session_id
    );
    toolbarStore.setSelectedDatasources(userDataSources);

    selectedSkillName.value = newSkillName;
    loadingSkills.value = false;
  }
);

watch(
  () => tenantStore.tenantId,
  async (oldTenantId, newTenantId) => {
    if (oldTenantId === newTenantId) {
      return;
    }

    await loadSkills();
  }
);
</script>

<template>
  <div class="w-fit">
    <TooltipProvider>
      <Select
        v-model="selectedSkillName"
        v-if="selectedSkillName !== undefined"
      >
        <SelectTrigger class="!shadow-md !border-0 truncate max-w-60">
          <SelectValue class="!flex !items-center gap-2 p-2">
            <Brain class="text-primary shrink-0" />
            <p class="truncate" v-if="!loadingSkills">
              {{ selectedSkillName }}
            </p>
            <Loader2 v-if="loadingSkills" class="animate-spin h-5 w-5" />
          </SelectValue>
        </SelectTrigger>
        <SelectContent v-if="!loadingSkills">
          <SelectItem
            v-for="skill in skills"
            :key="skill.name"
            :value="skill.name"
            :disabled="
              skill.name === Skills.CHAT_WITH_DOCUMENTS && isSkillDisabled
            "
          >
            <Tooltip>
              <TooltipTrigger as-child>
                <p class="flex items-center gap-1">
                  {{ skill.name }}
                  <CircleAlert
                    class="h-4 w-4"
                    v-if="
                      skill.name === Skills.CHAT_WITH_DOCUMENTS &&
                      isSkillDisabled
                    "
                  />
                </p>
                <p class="text-xs text-gray-500">{{ skill.description }}</p>
              </TooltipTrigger>
              <TooltipContent
                side="top"
                class="z-[100]"
                v-if="
                  skill.name === Skills.CHAT_WITH_DOCUMENTS && isSkillDisabled
                "
              >
                You must create a datasource to use this skill.
              </TooltipContent>
            </Tooltip>
          </SelectItem>
        </SelectContent>
      </Select>
    </TooltipProvider>
  </div>
</template>
