<script setup lang="ts">
import { ref } from "vue";
import apiClient from "@/services/api_client";

interface Version {
  version: string;
  url?: string;
  date?: string;
  sha1?: string;
}

const version = ref<Version>({ version: "loading..." });

async function getVersion() {
  const response = await apiClient.get("/version");
  version.value = await response.json();
}

getVersion();
</script>

<template>
  <a :href="version.url">Version: {{ version.version }}</a>
</template>
