import apiClient from "@/services/api_client";
import {
  getMicrosoftToken,
  handleMicrosoftConnection,
} from "@/services/auth_microsoft";
import { useUserStore } from "@/stores/user";
import { AccountInfo } from "@azure/msal-browser";
import {
  getSessionToken as getDescopeToken,
  useSession,
} from "@descope/vue-sdk";
import { defineStore } from "pinia";
import { ref, watch } from "vue";

export const useAuthStore = defineStore("auth", () => {
  const {
    isAuthenticated: isDescopeAuthenticated,
    isLoading: isDescopeLoading,
  } = useSession();
  const userStore = useUserStore();

  const isAuthenticated = ref<boolean>(false);
  const isLoading = ref<boolean>(true);
  const isInsideTeams = ref(false);
  const isSiteReadAll = ref<boolean>(true);
  const microsoftClientId = ref<string>();
  const microsoftAccount = ref<AccountInfo>();
  const azureTenantId = ref<string>("");
  const userToken = ref<string | undefined>("");

  watch(isDescopeAuthenticated, async () => {
    updateState();
  });

  watch(isDescopeLoading, async () => {
    updateState();
  });

  async function updateState() {
    // double check that these conditions are correct, expecially the isLoading
    isAuthenticated.value = isDescopeAuthenticated.value || isInsideTeams.value;
    isLoading.value = isDescopeLoading.value && !isInsideTeams.value;
  }

  async function checkTeams() {
    try {
      const { app } = await import("@microsoft/teams-js");
      await app.initialize();
      isInsideTeams.value = true;
      userStore.fetchTeamsUser();
    } catch (e) {
      isInsideTeams.value = false;
    }
    updateState();
  }

  async function getSessionToken() {
    if (isInsideTeams.value) {
      const { authentication } = await import("@microsoft/teams-js");
      return await authentication.getAuthToken();
    }
    return getDescopeToken();
  }

  async function updateCredentials(tenantId: string) {
    if (isInsideTeams.value) {
      console.log("Inside Teams, skipping Microsoft connection");
      return;
    }
    if (!tenantId) {
      return;
    }
    const getCredentials = await apiClient.getSharePointCredentials(tenantId);
    const getIsSiteReadAll = await apiClient.isSiteReadAll(tenantId);
    const credentials = getCredentials;
    if (!credentials) {
      return;
    }
    isSiteReadAll.value = getIsSiteReadAll;
    const account = await handleMicrosoftConnection(
      credentials.microsoft_app_id,
      credentials.azure_tenant_id,
      isSiteReadAll.value
    );
    microsoftAccount.value = account;
    microsoftClientId.value = credentials.microsoft_app_id;
    azureTenantId.value = credentials.azure_tenant_id;
  }

  async function aquireToken(): Promise<string | undefined> {
    if (isInsideTeams.value) {
      console.log("Retrieving token directly from teams");
      userToken.value = await getSessionToken();
    }

    if (
      microsoftAccount.value &&
      microsoftClientId.value &&
      azureTenantId.value &&
      isSiteReadAll.value
    ) {
      try {
        userToken.value = await getMicrosoftToken(
          microsoftAccount.value,
          microsoftClientId.value,
          azureTenantId.value,
          isSiteReadAll.value
        );
      } catch (error) {
        console.error("Error acquiring token:", error);
      }
    }
    return userToken.value;
  }

  return {
    isAuthenticated,
    isLoading,
    checkTeams,
    isInsideTeams,
    getSessionToken,
    updateCredentials,
    aquireToken,
  };
});
