<script setup lang="ts">
import { useShepherd } from "vue-shepherd";
import { useTenantStore } from "@/stores/tenant";
import { upsertOnboardingTour } from "@/services/onboarding_tour";

const { tenantId } = useTenantStore();

const chatTour = useShepherd({
  useModalOverlay: true,
  exitOnEsc: false,
  defaultStepOptions: {
    scrollTo: true,
    canClickTarget: false,
    modalOverlayOpeningRadius: 10,
    highlightClass: "shepherd_highlight",
    cancelIcon: {
      enabled: true,
    },
  },
});

chatTour.addSteps([
  {
    id: "greetings",
    title: "Welcome to the Playground!",
    text: "This is your space to explore and experiment with your research. Let's start by showing you how to conduct a search.",
    buttons: [
      {
        text: "Skip demo",
        action: async () => {
          if (tenantId) {
            await upsertOnboardingTour({
              tenantId,
              onboardingType: "chat",
              status: "skipped",
            });
          }
          chatTour.complete();
        },
        classes: "skip_demo_button",
      },
      {
        text: "Start tour",
        action: chatTour.next,
      },
    ],
    cancelIcon: {
      enabled: false,
    },
  },
  {
    id: "search_bar",
    title: "Conducting a Search",
    text: 'To begin your research, simply enter a query or topic into the search bar. For example, you could try "How to succeed with your business model"',
    attachTo: {
      element: "#search_bar",
      on: "top",
    },
    buttons: [
      {
        text: "Previous",
        action: chatTour.back,
      },
      {
        text: "Next",
        action: chatTour.next,
      },
    ],
  },
  {
    id: "chat_conversation",
    title: "Chat Conversation",
    text: "Once you submit your query, Playground will analyze your request and provide a relevant and informative response as quickly as possible.",
    attachTo: {
      element: "#message-container",
      on: "top-start",
    },
    buttons: [
      {
        text: "Previous",
        action: chatTour.back,
      },
      {
        text: "Next",
        action: chatTour.next,
      },
    ],
    arrow: false,
  },
  {
    id: "datasource_selector",
    title: "Choosing a Data Source",
    text: "To delve deeper into your topic, you can choose your data sources. Each source is a collection of your documents which will provide relevant context for your research",
    attachTo: {
      element: "#data_source_selector",
      on: "bottom",
    },
    buttons: [
      {
        text: "Previous",
        action: chatTour.back,
      },
      {
        text: "Next",
        action: chatTour.next,
      },
    ],
  },
  {
    id: "dataSourcesMenuButton",
    title: "Managing Your Data Sources",
    text: "To access and customize your data sources, go to the Data Sources menu",
    attachTo: {
      element: "#data_sources_button",
      on: "right",
    },
    buttons: [
      {
        text: "Previous",
        action: chatTour.back,
      },
      {
        text: "Next",
        action: chatTour.next,
      },
    ],
    modalOverlayOpeningRadius: 4,
  },
  {
    id: "savedPromptsMenuButton",
    title: "Managing Your saved prompts",
    text: "To access and manage your saved prompts, go to the Saved prompts menu",
    attachTo: {
      element: "#saved_prompts_button",
      on: "right",
    },
    buttons: [
      {
        text: "Previous",
        action: chatTour.back,
      },
      {
        text: "Finish",
        action: async () => {
          chatTour.complete();
          if (tenantId) {
            upsertOnboardingTour({
              tenantId,
              onboardingType: "chat",
              status: "done",
            });
          }
        },
      },
    ],
    modalOverlayOpeningRadius: 4,
  },
]);

chatTour.start();

chatTour.on("cancel", async () => {
  chatTour.complete();
  if (tenantId) {
    await upsertOnboardingTour({
      tenantId,
      onboardingType: "chat",
      status: "skipped",
    });
  }
});
</script>
