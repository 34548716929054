<script setup lang="ts">
import DataSourceSelector from "@/components/DataSourceSelector.vue";
import SkillSelector from "@/components/SkillSelector.vue";
import { computed } from "vue";
import { Skills } from "@/types";
import { useToolbarStore } from "@/stores/toolbarStore";

const tollbarStore = useToolbarStore();

const showDataSourcesDependingOnSkill = computed(() => {
  return (tollbarStore.selectedSkill as Skills) === Skills.CHAT_WITH_DOCUMENTS;
});
</script>

<template>
  <div class="flex gap-4 absolute top-8 left-4 z-40">
    <SkillSelector />
    <DataSourceSelector
      :disabled="!showDataSourcesDependingOnSkill"
      id="data_source_selector"
    />
  </div>
</template>
