<script setup lang="ts">
import { getSavedPrompts } from "@/services/saved_prompts";
import { useAuthStore } from "@/stores/auth";
import { useTenantStore } from "@/stores/tenant";
import { SavedPrompt } from "@/types";
import { computed, onMounted, ref, watch } from "vue";
import { Loader2 } from "lucide-vue-next";
import { useSavedPromptStore } from "@/stores/savedPrompts";

const tenantStore = useTenantStore();
const auth = useAuthStore();
const savedPromptStore = useSavedPromptStore();

const savedPrompts = ref<SavedPrompt[]>([]);
const loadingSavedPrompts = ref(true);

const loading = computed(() => {
  return auth.isLoading || loadingSavedPrompts.value;
});

async function loadSavedPrompts() {
  if (!tenantStore.tenantId) {
    return;
  }
  try {
    const orderedPrompts = await getSavedPrompts(tenantStore.tenantId);
    orderedPrompts.sort((a, b) => a.order - b.order);
    savedPrompts.value = orderedPrompts.map((elem) => elem.prompt);
  } catch (error) {
    console.error(error);
  } finally {
    loadingSavedPrompts.value = false;
  }
}

const insertPrompt = async (content: string) => {
  if (!content) return;
  savedPromptStore.insertPrompt(content);
};

watch(
  () => tenantStore.tenantId,
  async (newTenantId) => {
    if (newTenantId) {
      loadingSavedPrompts.value = true;
      await loadSavedPrompts();
    }
  }
);

onMounted(async () => {
  if (tenantStore.tenantId) {
    await loadSavedPrompts();
  }
});
</script>
<template>
  <h3 class="font-medium mb-2">Insert prompts</h3>
  <ul class="saved_prompts_list text-sm list-none p-0 flex flex-col gap-4">
    <Loader2 class="w-4 h-4 animate-spin" v-if="loading" />
    <li
      class="px-3 py-2 flex flex-col gap-1 rounded-md bg-primary10 truncate cursor-pointer hover:bg-primary20"
      v-for="prompt in savedPrompts"
      :key="prompt.id"
      @click="insertPrompt(prompt.content)"
    >
      <p class="font-medium truncate">
        {{ prompt.name }}
      </p>
      <p class="text-xs text-muted-foreground truncate">
        {{ prompt.content }}
      </p>
    </li>
  </ul>
</template>
