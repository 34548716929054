<script setup lang="ts">
import { Sidebar, SidebarContent } from "@/components/ui/sidebar";
import SavedPromptsQuickAcess from "@/components/QuickAccessBar/SavedPromptsQuickAcess.vue";
import NotificationsQuickAccess from "@/components/QuickAccessBar/NotificationsQuickAccess.vue";
import DatasourceDocumentsQuickAcess from "@/components/QuickAccessBar/DatasourceDocumentsQuickAcess.vue";
import { useToolbarStore } from "@/stores/toolbarStore";
const toolbarStore = useToolbarStore();
</script>
<template>
  <Sidebar
    side="right"
    collapsible="offcanvas"
    class="border-none border-0 pt-6 pb-4 pr-3 bg-primary10"
  >
    <SidebarContent class="px-3 py-3 bg-background rounded-lg">
      <NotificationsQuickAccess
        v-if="toolbarStore.selectedMenu === 'notifications'"
      />
      <SavedPromptsQuickAcess v-if="toolbarStore.selectedMenu === 'prompts'" />
      <DatasourceDocumentsQuickAcess
        v-if="toolbarStore.selectedMenu === 'documents'"
      />
    </SidebarContent>
  </Sidebar>
</template>
