<script setup lang="ts">
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { SavedPrompt } from "@/types";
import {
  deleteSavedPrompt,
  getSavedPrompts,
  updateSavedPromptOrder,
} from "@/services/saved_prompts";
import { useTenantStore } from "@/stores/tenant";
import { useAuthStore } from "@/stores/auth";
import { ref, computed, onMounted } from "vue";
import { Loader2 } from "lucide-vue-next";
import SavedPromptCard from "@/components/SavedPromptCard.vue";
import SavedPromptCreate from "@/components/SavedPromptCreate.vue";
import SavedPromptShow from "@/components/SavedPromptShow.vue";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

const isDialogOpen = ref(false);
const isDeleteDialogOpen = ref<boolean>(false);
const tenantStore = useTenantStore();
const auth = useAuthStore();

const savedPrompts = ref<SavedPrompt[]>([]);
const loadingSavedPrompts = ref(true);
const savedPromptToShow = ref<SavedPrompt | null>(null);
const PageReloading = ref<boolean>(false);

const loading = computed(() => {
  return auth.isLoading || loadingSavedPrompts.value;
});

async function loadSavedPrompts() {
  if (!tenantStore.tenantId) {
    return;
  }
  try {
    const savedPromptsResult = await getSavedPrompts(tenantStore.tenantId);
    const formatedSavedPrompt = savedPromptsResult.map((savedPrompt) => {
      return { ...savedPrompt.prompt, order: savedPrompt.order };
    });
    savedPrompts.value = formatedSavedPrompt;
  } catch (error) {
    console.error(error);
  }
  loadingSavedPrompts.value = false;
}

onMounted(async () => {
  if (!tenantStore.tenantId) {
    return;
  }
  await loadSavedPrompts();
});

function handleSavedPromptShow(savedPrompt: SavedPrompt) {
  isDialogOpen.value = true;
  savedPromptToShow.value = savedPrompt;
}

const handleOpenDeleteDialog = async (savedPrompt: SavedPrompt) => {
  savedPromptToShow.value = savedPrompt;
  isDeleteDialogOpen.value = true;
};

const handleDeletePrompt = async () => {
  if (!tenantStore.tenantId || !savedPromptToShow.value) {
    return;
  }
  try {
    isDeleteDialogOpen.value = false;
    PageReloading.value = true;
    await deleteSavedPrompt(savedPromptToShow.value.id, tenantStore.tenantId);
    await loadSavedPrompts();
  } catch (error) {
    console.error(error);
  } finally {
    PageReloading.value = false;
  }
};

const handleSavedPromptOrder = async (
  savedPrompt: SavedPrompt,
  newOrder: number
) => {
  if (
    !tenantStore.tenantId ||
    newOrder < 0 ||
    newOrder >= savedPrompts.value.length
  ) {
    return;
  }
  PageReloading.value = true;
  try {
    await updateSavedPromptOrder(
      tenantStore.tenantId,
      savedPrompt.id,
      newOrder
    );
    await loadSavedPrompts();
  } catch (error) {
    console.error(error);
  } finally {
    PageReloading.value = false;
  }
};
</script>

<template>
  <Dialog v-model:open="isDeleteDialogOpen">
    <DialogContent
      class="flex flex-col items-center text-center w-fit p-8 !max-w-full"
    >
      <DialogHeader>
        <DialogTitle>Delete prompt</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        Are you sure you want to delete this prompt?
      </DialogDescription>
      <div class="flex gap-4 items-center">
        <Button variant="destructive" @click="handleDeletePrompt"
          >Delete</Button
        >
        <Button variant="secondary" @click="isDeleteDialogOpen = false"
          >Cancel</Button
        >
      </div>
    </DialogContent>
  </Dialog>
  <SavedPromptShow
    v-model:isDialogOpen="isDialogOpen"
    :savedPrompt="savedPromptToShow"
    :loadSavedPrompts="loadSavedPrompts"
  />
  <div
    class="reloading_overlay z-20 absolute h-screen top-0 left-0 flex items-center justify-center w-full min-h-full bg-primary30 opacity-40"
    v-if="PageReloading"
  >
    <Loader2 class="animate-spin w-8 h-8 text-foreground" />
  </div>
  <div class="h-screen flex flex-col overflow-y-scroll gap-4 p-8 relative">
    <div class="header">
      <Breadcrumb class="mb-2">
        <BreadcrumbList class="p-0 list-none">
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Manage prompts</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <h1 class="font-spartan font-semibold text-2xl">Manage prompts</h1>
    </div>
    <div class="relative h-full">
      <Loader2 v-if="loading" class="animate-spin h-5 w-5 mt-4" />
      <div
        v-else
        class="prompts_list_container grid grid-flow-row grid-cols-[repeat(auto-fit,_minmax(350px,1fr))] auto-rows-auto gap-4"
      >
        <div id="add_source_button">
          <SavedPromptCreate :loadSavedPrompts="loadSavedPrompts" />
        </div>
        <SavedPromptCard
          v-for="savedPrompt in savedPrompts"
          :key="savedPrompt.id"
          :savedPrompt="savedPrompt"
          @edit="handleSavedPromptShow(savedPrompt)"
          @delete="handleOpenDeleteDialog(savedPrompt)"
          @reorder="handleSavedPromptOrder(savedPrompt, $event.newOrder)"
        />
      </div>
    </div>
  </div>
</template>
