<script setup lang="ts">
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader } from "lucide-vue-next";

import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";

const auth = useAuthStore();
const user = useUserStore();
</script>

<template>
  <Card v-if="auth.isLoading">
    <CardHeader>
      <Loader class="animate-spin h-5 w-5 mt-4" />
    </CardHeader>
  </Card>
  <Card v-else class="h-screen">
    <CardHeader>
      <CardTitle> Welcome, {{ user.name }} </CardTitle>
    </CardHeader>
    <CardContent> Email: {{ user.email }} </CardContent>
  </Card>
</template>
