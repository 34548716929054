import { createConfigCatClient } from "@/plugins/configcat";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useConfigCatStore = defineStore("configCat", () => {
  const webUrlUpload = ref<boolean>(false);
  const userManagementTab = ref<boolean>(false);
  const restrictDataSourceCreateToAdmins = ref<boolean>(false);
  const onboarding = ref<boolean>(false);
  const indexingQuotas = ref<boolean>(false);

  async function fetchConfigCatFlags(user: any) {
    if (user) {
      const configCatClient = createConfigCatClient(user.userId, user.email);

      // fetch all flag values
      webUrlUpload.value = await configCatClient.getValueAsync(
        "webUrlUpload",
        false
      );
      userManagementTab.value = await configCatClient.getValueAsync(
        "userManagementTab",
        false
      );
      restrictDataSourceCreateToAdmins.value =
        await configCatClient.getValueAsync(
          "restrictDataSourceCreateToAdmins",
          false
        );
      onboarding.value = await configCatClient.getValueAsync(
        "onboarding",
        false
      );
      indexingQuotas.value = await configCatClient.getValueAsync(
        "indexingQuotas",
        false
      );
    }
  }

  return {
    webUrlUpload,
    userManagementTab,
    restrictDataSourceCreateToAdmins,
    onboarding,
    indexingQuotas,
    fetchConfigCatFlags,
  };
});
