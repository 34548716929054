import * as configcat from "configcat-js";

export function createConfigCatClient(userId: string, userEmail: string) {
  // Set the log level to track how your feature flags were evaluated.
  const logger = configcat.createConsoleLogger(configcat.LogLevel.Warn);
  const configCatUser = new configcat.User(userId, userEmail);

  const configCatClient = configcat.getClient(
    import.meta.env.VITE_CONFIGCAT_SDK_KEY,
    configcat.PollingMode.AutoPoll,
    {
      logger: logger,
      defaultUser: configCatUser,
    },
  );

  return configCatClient;
}
