<script setup lang="ts">
import { Button } from "@/components/ui/button";
import { computed, ref, onMounted } from "vue";
import { DataSource } from "@/types";
import { useTenantStore } from "@/stores/tenant";
import { useRouter } from "vue-router";
import { getDataSources, deleteDataSource } from "@/services/data_source";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

const router = useRouter();

const props = defineProps<{
  dataSource: DataSource | null;
}>();

const deleteInput = ref("");
const requiredDeleteText = computed(() => `delete ${props.dataSource?.name}`);
const isDeleteButtonDisabled = computed(
  () => deleteInput.value !== requiredDeleteText.value
);
const tenantStore = useTenantStore();
const selectedTenant = ref<string>(tenantStore.tenantId || "");
const dataSources = ref<DataSource[]>([]);

onMounted(() => {
  loadDataSources();
});

async function loadDataSources() {
  dataSources.value = await getDataSources(selectedTenant.value);
}
async function handleDeleteDataSource() {
  if (props.dataSource) {
    await deleteDataSource(
      props.dataSource.name,
      props.dataSource.id,
      selectedTenant.value
    );
  }
  router.push({ name: "Data sources" });
}

loadDataSources();
</script>

<template>
  <AlertDialog>
    <AlertDialogTrigger asChild>
      <Button variant="destructive" class="mt-8 h-8 w-fit bg-red-400">
        Delete data source
      </Button>
    </AlertDialogTrigger>
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle
          >Are you sure you want to delete the data source "{{
            props.dataSource?.name
          }}"?</AlertDialogTitle
        >
      </AlertDialogHeader>
      <AlertDialogDescription>
        Please type <strong>"delete {{ props.dataSource?.name }}"</strong> to
        confirm.
        <input
          type="text"
          v-model="deleteInput"
          class="mt-2 p-2 border border-gray-300 rounded"
          placeholder="Type here"
        />
        <div v-if="dataSources.length <= 1" class="text-red-500 text-sm mt-2">
          <p>You cannot delete the last data source.</p>
          <p>Create a new one before deleting this one.</p>
        </div>
      </AlertDialogDescription>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <AlertDialogAction
          @click="handleDeleteDataSource"
          :disabled="isDeleteButtonDisabled || dataSources.length === 1"
          :class="[
            'bg-red-500 text-white transition-opacity duration-200',
            { 'opacity-50 cursor-not-allowed': isDeleteButtonDisabled },
            { 'hover:bg-red-500 hover:opacity-75': !isDeleteButtonDisabled },
          ]"
        >
          Delete
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
