<script setup lang="ts">
import { ref, Ref, inject } from "vue";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import apiClient from "@/services/api_client";
import { DataSource } from "@/types";
import { useToast } from "@/components/ui/toast/use-toast";

const dataSource = inject<Ref<DataSource>>("dataSource");

const newUrl = ref<string | undefined>(undefined);
const toast = useToast();

const uploadUrl = async (urls: string[]) => {
  console.log("Uploading urls", urls);

  if (urls.length === 0) {
    return;
  }

  const formData = new FormData();
  urls.forEach((url) => {
    formData.append("urls", url);
  });
  formData.append("data_source_id", dataSource?.value?.id.toString() ?? "");
  formData.append("tenant_id", dataSource?.value?.tenant_id?.toString() ?? "");

  try {
    await apiClient.postFormData("/index_url", formData);
    toast.toast({
      title: "Indexing in progress",
      description: "URL indexing started, it may take a few minutes",
      duration: 3000,
    });
  } catch (error) {
    toast.toast({
      title: "Uh oh! Something went wrong",
      description: "There was a problem with URL indexing",
      variant: "destructive",
      duration: 3000,
    });
    console.error("Error indexing url", error);
  }
};

const handleUrlSelection = async () => {
  if (!newUrl.value) {
    return;
  }
  const newUrls = newUrl.value.split(/[\s,]+/);
  await uploadUrl(newUrls);

  if (newUrl.value) {
    newUrl.value = "";
  }
};
</script>

<template>
  <div class="flex flex-col justify-center align-middle">
    <div class="h-8 flex">
      <Input
        class="h-8"
        id="Url"
        v-model="newUrl"
        placeholder="Url"
        spellcheck="false"
      />
      <Button
        @click="handleUrlSelection"
        type="submit"
        size="sm"
        class="h-8 ml-2 flex items-center gap-1.5"
        :disabled="!newUrl"
      >
        Add
      </Button>
    </div>
  </div>
</template>
