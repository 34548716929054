import { defineStore } from "pinia";
import { ref } from "vue";

export const useSavedPromptStore = defineStore("savedPrompt", () => {
  const selectedPromptContent = ref<string | null>(null);
  const insertPrompt = (prompt: string) => {
    selectedPromptContent.value = prompt;
  };

  return {
    selectedPromptContent,
    insertPrompt,
  };
});
