<script setup lang="ts">
import { RouterView, useRoute } from "vue-router";
import Sidebar from "./components/Sidebar.vue";
import LoginView from "./views/LoginView.vue";
import "shepherd.js/dist/css/shepherd.css";
import "@/style/style-shepherd.css";

import { useAuthStore } from "@/stores/auth";
import Toaster from "@/components/ui/toast/Toaster.vue";
import Toolbar from "@/components/QuickAccessBar/Toolbar.vue";
import { computed } from "vue";

const auth = useAuthStore();
auth.checkTeams();

const route = useRoute();
const isChatView = computed(() => route.name === "Chat");
</script>

<template>
  <Toaster />
  <div class="flex text-foreground bg-background" v-if="auth.isLoading"></div>
  <div
    class="flex text-foreground bg-background"
    v-else-if="auth.isAuthenticated"
  >
    <Sidebar />
    <div class="main_container relative h-screen flex-1 overflow-hidden">
      <RouterView />
    </div>
    <Toolbar v-if="isChatView" />
  </div>
  <LoginView v-else> </LoginView>
</template>
