<script setup lang="ts">
import { ref, Ref, inject } from "vue";
import { Button } from "@/components/ui/button";
import { MonitorUp } from "lucide-vue-next";
import { DataSource } from "@/types";
import { useToast } from "@/components/ui/toast/use-toast";
import { processFiles, acceptExtensionValue } from "@/services/upload_document";

const dataSource = inject<Ref<DataSource>>("dataSource")!.value;

const fileInput = ref<HTMLInputElement | null>(null);
const toast = useToast();

const onFilesSelected = async () => {
  const files = fileInput.value?.files;
  if (files) {
    await processFiles(
      Array.from(files),
      dataSource.tenant_id,
      toast,
      dataSource.id.toString()
    );
    if (fileInput.value) {
      fileInput.value.value = "";
    }
  }
};

const triggerFileInput = () => {
  fileInput.value?.click();
};
</script>

<template>
  <Button
    variant="outline"
    class="flex justify-center h-8 m-2"
    @click="triggerFileInput"
  >
    <MonitorUp class="h-4 w-4 mr-2" />
    Upload from local
  </Button>
  <input
    type="file"
    :accept="acceptExtensionValue"
    ref="fileInput"
    class="hidden"
    multiple
    @change="onFilesSelected"
  />
</template>
