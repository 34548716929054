<script setup lang="ts">
import { ref } from "vue";
import { Button } from "@/components/ui/button";
import { Paperclip } from "lucide-vue-next";
import { processFiles, acceptExtensionValue } from "@/services/upload_document";
import { useToast } from "@/components/ui/toast/use-toast";
import { Skills } from "@/types";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../ui/tooltip";
import { useToolbarStore } from "@/stores/toolbarStore";
import { getDatasourceByChatSession } from "@/services/data_source";

const fileInput = ref<HTMLInputElement | null>(null);
const toast = useToast();
const toolbarStore = useToolbarStore();
const emit = defineEmits(["selectSkill"]);
const { tenantId, session_id } = defineProps(["tenantId", "session_id"]);

const onFilesSelected = async () => {
  if (!session_id || !tenantId || !fileInput.value) return;

  const files = fileInput.value.files;

  if (files) {
    try {
      await processFiles(Array.from(files), tenantId, toast, "-1", session_id);

      const chatSessionDataSource = await getDatasourceByChatSession({
        chatSessionId: session_id,
        tenantId: tenantId,
      });

      if (fileInput.value) {
        fileInput.value.value = "";
      }

      emit("selectSkill", Skills.CHAT_WITH_DOCUMENTS);
      toolbarStore.setSelectedDatasources(
        toolbarStore.selectedDataSources &&
          !toolbarStore.selectedDataSources.find(
            (dataSource) => dataSource.id === chatSessionDataSource.id
          )
          ? [...toolbarStore.selectedDataSources, chatSessionDataSource]
          : [chatSessionDataSource]
      );
      toolbarStore.reloadDatasourceDocuments = true;
      toolbarStore.selectedMenu = "documents";
    } catch (error) {
      console.error(error);
    }
  }
};

const triggerFileInput = () => {
  fileInput.value?.click();
};
</script>

<template>
  <TooltipProvider>
    <div class="upload_button flex justify-center items-center w-8">
      <Tooltip>
        <TooltipTrigger as-child>
          <Button
            variant="ghost"
            class="flex justify-center h-8 w-8 rounded-full p-0"
            @click="triggerFileInput"
          >
            <Paperclip class="h-5 w-5" />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">Attach files</TooltipContent>
        <input
          type="file"
          :accept="acceptExtensionValue"
          ref="fileInput"
          class="hidden"
          multiple
          @change="onFilesSelected"
        />
      </Tooltip>
    </div>
  </TooltipProvider>
</template>
