<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import ChatSessionArchive from "@/components/ChatSessionArchive.vue";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { SquarePen, Ellipsis } from "lucide-vue-next";
import { ChatSession, Skills } from "@/types";
import { useChatSessionsStore } from "@/stores/chatSessions";
import { useTenantStore } from "@/stores/tenant";
import { setUserSkill } from "@/services/skill";

const router = useRouter();
const currentRoute = useRoute();
const chatSessionsStore = useChatSessionsStore();
const tenantStore = useTenantStore();

const chatSessionBeingRenamed = ref<string | null>(null);
const newChatSessionName = ref<string>("");
const chatSessionsContainer = ref<HTMLDivElement | null>(null);
const isArchiveDialogOpen = ref(false);
const chatSessionToArchive = ref<ChatSession | null>(null);

const chatSessions = computed(() => chatSessionsStore.chatSessions);

function isChatSessionSelected(chatSession: ChatSession) {
  return (
    chatSession.id.toString() ===
    (currentRoute.params.session_id?.toString() || "")
  );
}

async function handleNewChat() {
  if (!tenantStore.tenantId) {
    return;
  }
  const newChat = await chatSessionsStore.addChatSession(
    `New Chat`,
    tenantStore.tenantId
  );
  setUserSkill(tenantStore.tenantId, Skills.CHAT_WITH_DOCUMENTS, newChat.id);
  router.replace({ name: "Chat", params: { session_id: newChat.id } });
}

function handleChatSessionArchive(chatSession: ChatSession) {
  isArchiveDialogOpen.value = true;
  chatSessionToArchive.value = chatSession;
}

async function handleChatSessionEdition(chatSession: ChatSession) {
  if (chatSessionBeingRenamed.value === chatSession.id.toString()) {
    chatSessionBeingRenamed.value = null;
  } else {
    chatSessionBeingRenamed.value = chatSession.id.toString();
    newChatSessionName.value = chatSession.name;
  }
}

async function updateChatSessionName(chatSession: ChatSession) {
  if (newChatSessionName.value.trim() === "") {
    newChatSessionName.value = chatSession.name;
  } else if (newChatSessionName.value !== chatSession.name) {
    await chatSessionsStore.updateChatSession(
      chatSession.id,
      newChatSessionName.value
    );
    chatSessionsStore.moveChatSessionToTop(chatSession.id as string);
  }
  chatSessionBeingRenamed.value = null;
}

function scrollChatSessionsContainerToTop() {
  chatSessionsContainer.value?.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

onMounted(async () => {
  if (!tenantStore.tenantId) {
    return;
  }
  await chatSessionsStore.fetchChatSessions(tenantStore.tenantId);
});

watch(
  () => tenantStore.tenantId,
  async (newTenantId, oldTenantId) => {
    if (!newTenantId || oldTenantId === newTenantId) {
      return;
    }
    await chatSessionsStore.fetchChatSessions(newTenantId);
  }
);

watch(
  () => chatSessionsStore.shouldScrollToTop,
  (newVal) => {
    if (newVal) {
      scrollChatSessionsContainerToTop();
      chatSessionsStore.shouldScrollToTop = false;
    }
  }
);
</script>
<template>
  <ChatSessionArchive
    v-model:isArchiveDialogOpen="isArchiveDialogOpen"
    :chatSession="chatSessionToArchive"
  />
  <div class="chat_sessions_container flex flex-col gap-1 min-h-0 flex-1">
    <hr class="border-foreground90 border" />
    <div class="new_chat_item flex justify-between items-center gap-2 pl-3">
      <p class="font-spartan font-normal text-lg">Chats</p>
      <Button
        @click="handleNewChat"
        class="bg-foreground w-9 h-9 rounded-lg p-0 hover:bg-foreground90"
      >
        <TooltipProvider class="w-fit">
          <Tooltip>
            <TooltipTrigger as-child>
              <SquarePen
                class="w-5 h-5 text-primary50 focus:border-0 focus:outline-0"
              />
            </TooltipTrigger>
            <TooltipContent>Create new chat</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </Button>
    </div>
    <div
      ref="chatSessionsContainer"
      class="chat_sessions flex flex-col gap-1 text-sm text-primary60 list-none p-0 w-full overflow-y-auto min-h-0 flex-1"
    >
      <div
        v-for="chatSession in chatSessions"
        :key="chatSession.id"
        class="chat_session group relative w-full"
      >
        <div
          class="flex gap-2 justify-between items-center rounded-lg pl-3 pr-1 py-1 transition-all"
          :class="{
            'bg-foreground80':
              isChatSessionSelected(chatSession) ||
              chatSessionBeingRenamed === chatSession.id.toString(),
          }"
        >
          <form
            v-if="chatSessionBeingRenamed === chatSession.id.toString()"
            @submit.prevent="updateChatSessionName(chatSession)"
            class="bg-transparent w-full"
          >
            <Input
              v-model="newChatSessionName"
              @keydown.enter.prevent="updateChatSessionName(chatSession)"
              @keydown.escape="updateChatSessionName(chatSession)"
              @blur="updateChatSessionName(chatSession)"
              class="whitespace-nowrap h-6 px-0 rounded-none bg-foreground80 border-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
              maxlength="40"
            />
          </form>
          <RouterLink
            v-else
            :to="`/chat/${chatSession.id}`"
            class="truncate flex-grow"
          >
            <p
              class="chat_session_name truncate group-hover:text-background"
              :class="{
                'text-background': isChatSessionSelected(chatSession),
              }"
            >
              {{ chatSession.name }}
            </p>
          </RouterLink>
          <div
            class="shrink-0 p-1 rounded-lg bg-foreground90 opacity-0 group-hover:opacity-100 flex items-center"
            :class="
              isChatSessionSelected(chatSession) ||
              chatSessionBeingRenamed === chatSession.id.toString()
                ? 'opacity-100 hover:bg-foreground'
                : ''
            "
          >
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Ellipsis class="h-4 w-4 cursor-pointer" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  @click="handleChatSessionEdition(chatSession)"
                >
                  {{
                    chatSessionBeingRenamed === chatSession.id.toString()
                      ? "Cancel Rename"
                      : "Rename"
                  }}
                </DropdownMenuItem>
                <DropdownMenuItem
                  @click="handleChatSessionArchive(chatSession)"
                >
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
