<script setup lang="ts">
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { UserManagement } from "@descope/vue-sdk";
import { useTenantStore } from "@/stores/tenant";
const tenantStore = useTenantStore();
</script>

<template>
  <Card class="h-full">
    <CardHeader>
      <CardTitle> User management </CardTitle>
    </CardHeader>
    <CardContent>
      <UserManagement
        :tenant="tenantStore.tenantId ?? ''"
        widget-id="user-management-widget"
        theme="light"
      />
    </CardContent>
  </Card>
</template>
