<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useTenantStore } from "@/stores/tenant";
import { useToolbarStore } from "@/stores/toolbarStore";
import { getDatasourceDocuments } from "@/services/data_source";
import Separator from "@/components/ui/separator/Separator.vue";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-vue-next";
import { DataSource, IndexingStatus, Item } from "@/types";

type Props = {
  datasource: DataSource;
};
const { datasource } = defineProps<Props>();

const tenantStore = useTenantStore();
const toolbarStore = useToolbarStore();

const datasourceDocuments = ref<Item[]>([]);
const datasourceDocumentsCount = ref<number | null>(null);
const loading = ref<boolean>(false);
const pagination = ref({ page: 1, pageSize: 10 });

const showMoreButton = computed(() => {
  if (!datasourceDocumentsCount.value) return false;
  return (
    datasourceDocumentsCount.value >
    pagination.value.page * pagination.value.pageSize
  );
});

const loadDocuments = async (append = false) => {
  if (!tenantStore.tenantId || !datasource.id) {
    return;
  }
  loading.value = true;
  try {
    if (!append) datasourceDocuments.value = [];
    const response = await getDatasourceDocuments({
      dataSourceIds: [+datasource.id],
      tenantId: tenantStore.tenantId,
      page: pagination.value.page,
      pageSize: pagination.value.pageSize,
    });
    datasourceDocumentsCount.value = response.count;
    datasourceDocuments.value = append
      ? [
          ...datasourceDocuments.value,
          ...response.items.filter(
            (item) => item.indexing_status === IndexingStatus.INDEXED
          ),
        ]
      : response.items.filter(
          (item) => item.indexing_status === IndexingStatus.INDEXED
        );
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

const handleLoadMore = () => {
  pagination.value.page += 1;
  loadDocuments(true);
};

onMounted(async () => {
  await loadDocuments();
});

watch(
  () => tenantStore.tenantId,
  async (newTenantId, oldTenantId) => {
    if (newTenantId !== oldTenantId) {
      await loadDocuments();
    }
  }
);

watch(
  () => toolbarStore.reloadDatasourceDocuments,
  async (reloadDocument) => {
    if (reloadDocument) {
      await loadDocuments();
      toolbarStore.reloadDatasourceDocuments = false;
    }
  }
);
</script>

<template>
  <Loader2
    class="w-4 h-4 animate-spin mt-2"
    v-if="loading && datasourceDocumentsCount === null"
  />
  <div
    v-if="datasourceDocumentsCount && datasourceDocumentsCount > 0"
    class="flex flex-col gap-3"
  >
    <div
      v-for="(item, index) in datasourceDocuments"
      :key="item.id"
      class="flex flex-col gap-3"
    >
      <p class="truncate text-xs">{{ item.name }}</p>
      <Separator v-if="index !== datasourceDocuments.length - 1" />
    </div>
    <Button
      @click="handleLoadMore"
      v-if="showMoreButton"
      variant="secondary"
      size="xs"
      class="w-fit mt-1 text-xs"
    >
      Show more
    </Button>
    <Loader2 class="w-4 h-4 animate-spin mt-2" v-if="loading" />
  </div>
  <p
    class="text-primary60 text-xs"
    v-if="!loading && datasourceDocumentsCount === 0"
  >
    No documents attached yet
  </p>
</template>
