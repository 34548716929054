import { createApp } from "vue";
import { createPinia } from "pinia";
import "./style.css";
import router from "./router";
import descope from "@descope/vue-sdk";
import App from "./App.vue";

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);

app.use(router);
app.use(descope, {
  projectId: import.meta.env.VITE_DESCOPE_PROJECT_ID || "",
  baseUrl: "",
  sessionTokenViaCookie: true,
});

app.mount("#app");
