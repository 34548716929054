<script setup lang="ts">
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Checkbox } from "@/components/ui/checkbox";

import { computed, onMounted, ref, watch } from "vue";
import {
  getDataSources,
  getSelectedDataSources,
  selectDataSources,
  getDatasourceByChatSession,
} from "@/services/data_source";
import { useTenantStore } from "@/stores/tenant";
import { DataSource } from "@/types";
import { useToolbarStore } from "@/stores/toolbarStore";

import {
  Database,
  Loader2,
  Globe,
  LockKeyhole,
  ChevronDown,
} from "lucide-vue-next";
import { useRoute } from "vue-router";

const { disabled } = defineProps({
  disabled: {
    type: Boolean,
    default: false, // default to not disabled
  },
});
const selectorDisabled = computed(() => {
  return disabled || loadingDataSources.value;
});
const tenantStore = useTenantStore();
const toolbarStore = useToolbarStore();
const selectedTenant = ref<string | undefined>("");

const route = useRoute();
const chatSessionId = ref<string | undefined>(
  route.params.session_id as string
);

const MAX_HEIGHT = 192; // h-48
const ITEM_HEIGHT = 32; // h-8

const privateScrollAreaHeight = computed(() => {
  const items = privateDatasources.value.length;
  const height = items * ITEM_HEIGHT;
  return height > MAX_HEIGHT ? MAX_HEIGHT : height;
});

const publicScrollAreaHeight = computed(() => {
  const items = publicDatasources.value.length;
  const height = items * ITEM_HEIGHT;
  return height > MAX_HEIGHT ? MAX_HEIGHT : height;
});

const dataSources = ref<DataSource[]>([]);
const loadingDataSources = ref(true);
const selectedDataSources = ref<string[]>([]);
const menuOpen = ref(false);

const displayedSelectedDataSources = computed(() => {
  if (selectedDataSources.value.length === 0) {
    return "None";
  }
  if (selectedDataSources.value.length === 1) {
    return selectedDataSources.value[0] !== chatSessionId.value
      ? truncateString(selectedDataSources.value[0], 20)
      : "Attached files";
  }

  return `${
    selectedDataSources.value[0] !== chatSessionId.value
      ? truncateString(selectedDataSources.value[0], 15)
      : "Attached files"
  } +${selectedDataSources.value.length - 1}`;
});

function truncateString(str: string, maxLength: number): string {
  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
}

const publicDatasources = computed(() => {
  return dataSources.value.filter(
    (dataSource) => dataSource.visibility === "public"
  );
});
const privateDatasources = computed(() => {
  return dataSources.value.filter(
    (dataSource) => dataSource.visibility === "private"
  );
});
async function loadDataSources() {
  if (!selectedTenant.value || !chatSessionId.value) {
    return;
  }

  loadingDataSources.value = true;

  try {
    const [attachedFilesDatasource, datasources, userDataSources] =
      await Promise.all([
        getDatasourceByChatSession({
          chatSessionId: chatSessionId.value as string,
          tenantId: selectedTenant.value,
        }),
        getDataSources(selectedTenant.value),
        getSelectedDataSources(selectedTenant.value, chatSessionId.value),
      ]);
    dataSources.value = attachedFilesDatasource
      ? [attachedFilesDatasource, ...datasources]
      : [...datasources];

    const attachedFilesdatasourceSelected = userDataSources.find(
      (datasource) => datasource.chat_session_id
    );

    if (
      attachedFilesdatasourceSelected &&
      attachedFilesdatasourceSelected.id !== attachedFilesDatasource.id
    ) {
      const index = userDataSources.indexOf(attachedFilesdatasourceSelected);
      userDataSources.splice(index, 1, attachedFilesDatasource);
      await selectDataSources(
        chatSessionId.value,
        dataSources.value,
        userDataSources.map((dataSource) => dataSource.name),
        selectedTenant.value
      );
    }

    toolbarStore.setSelectedDatasources(userDataSources);
    selectedDataSources.value = userDataSources.map(
      (dataSource) => dataSource.name
    );
  } catch (error) {
    console.error("Failed to load data sources:", error);
  } finally {
    loadingDataSources.value = false;
  }
}

function handleCheckboxChange(isChecked: boolean, dataSource: DataSource) {
  const index = selectedDataSources.value.indexOf(dataSource.name);
  if (isChecked) {
    if (!selectedDataSources.value.includes(dataSource.name)) {
      selectedDataSources.value.push(dataSource.name);
    }
  } else {
    if (index > -1) {
      selectedDataSources.value.splice(index, 1);
    }
  }
}

function toggleCheckbox(event: Event, dataSource: DataSource) {
  event.preventDefault();
  const isChecked = !selectedDataSources.value.includes(dataSource.name);
  handleCheckboxChange(isChecked, dataSource);
}

onMounted(async () => {
  selectedTenant.value = tenantStore.tenantId;
  chatSessionId.value = route.params.session_id as string;
  await loadDataSources();
});

watch(
  () => tenantStore.tenantId,
  async (oldTenantId, newTenantId) => {
    if (oldTenantId === newTenantId) {
      return;
    }
    selectedTenant.value = tenantStore.tenantId;
    await loadDataSources();
  }
);

watch(
  () => chatSessionId.value,
  async () => {
    await loadDataSources();
  }
);

watch(
  () => route.params.session_id,
  async (newSessionId) => {
    chatSessionId.value = newSessionId as string;
  }
);

watch(
  () => toolbarStore.selectedDataSources,
  async (selection) => {
    if (selection) {
      selectedDataSources.value = selection.map(
        (dataSource) => dataSource.name
      );
    }
  }
);

watch(menuOpen, async (newVal, oldVal) => {
  if (!tenantStore.tenantId || !chatSessionId.value) {
    return;
  }
  if (oldVal && !newVal) {
    const selectedSources = dataSources.value.filter((dataSource) =>
      selectedDataSources.value.includes(dataSource.name)
    );

    if (selectedSources) {
      toolbarStore.setSelectedDatasources(selectedSources);
    }

    await selectDataSources(
      chatSessionId.value,
      dataSources.value,
      selectedDataSources.value,
      tenantStore.tenantId
    );
  }
});
</script>

<template>
  <div class="w-fit bg-background rounded-md">
    <DropdownMenu v-model:open="menuOpen">
      <DropdownMenuTrigger
        as-child
        class="!shadow-md !border-0 truncate max-w-60"
      >
        <Button
          variant="outline"
          :disabled="selectorDisabled"
          class="!flex !items-center gap-2 pl-5 pr-3 py-4 truncate"
        >
          <Database class="text-primary shrink-0" />
          <p class="truncate font-normal" v-if="!loadingDataSources">
            {{ selectorDisabled ? "None" : displayedSelectedDataSources }}
          </p>
          <Loader2 v-if="loadingDataSources" class="animate-spin h-5 w-5" />
          <ChevronDown class="shrink-0 w-4 h-4 ml-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent class="w-56">
        <div class="my-2">
          <DropdownMenuLabel
            class="privacy_tag text-muted-foreground font-medium flex gap-2 items-center h-fit w-fit mb-2 rounded-full px-2 py-1 bg-primary20 mt-1"
          >
            <LockKeyhole class="h-3 w-3" />
            <p class="text-xs">Private</p>
          </DropdownMenuLabel>
          <ScrollArea :style="{ height: privateScrollAreaHeight + 'px' }">
            <DropdownMenuItem
              v-for="dataSource in privateDatasources"
              :key="dataSource.id"
              class="flex items-center gap-2 cursor-pointer truncate"
              @select="toggleCheckbox($event, dataSource)"
            >
              <Checkbox
                :checked="selectedDataSources.includes(dataSource.name)"
                @update:checked="handleCheckboxChange($event, dataSource)"
                @click.stop
              />
              <span class="truncate">{{
                dataSource.chat_session_id ? "Attached files" : dataSource.name
              }}</span>
            </DropdownMenuItem>
          </ScrollArea>
        </div>
        <div v-if="publicDatasources.length > 0">
          <DropdownMenuSeparator />
          <DropdownMenuLabel
            class="privacy_tag text-muted-foreground font-medium flex gap-2 items-center h-fit w-fit mb-2 rounded-full px-2 py-1 bg-primary20 mt-2"
          >
            <Globe class="h-3 w-3" />
            <p class="text-xs">Public</p>
          </DropdownMenuLabel>
          <ScrollArea :style="{ height: publicScrollAreaHeight + 'px' }">
            <DropdownMenuItem
              v-for="dataSource in publicDatasources"
              :key="dataSource.id"
              class="flex items-center gap-2 cursor-pointer truncate"
              @select="toggleCheckbox($event, dataSource)"
            >
              <Checkbox
                :checked="selectedDataSources.includes(dataSource.name)"
                @update:checked="handleCheckboxChange($event, dataSource)"
                @click.stop
              />
              <span class="truncate">{{ dataSource.name }}</span>
            </DropdownMenuItem>
          </ScrollArea>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
</template>
