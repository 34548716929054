import apiClient from "@/services/api_client";
import { SavedPrompt, SavedPromptsList } from "@/types";
import { marked } from "marked";

export async function createSavedPrompt(
  name: string,
  content: string,
  visibility: string,
  tenantId: string
) {
  const url = "/saved_prompts";
  try {
    return await apiClient.post(url, {
      name: name,
      content: content,
      visibility: visibility,
      tenant_id: tenantId,
    });
  } catch (e) {
    console.error(e);
  }
}

export async function updateSavedPrompt(
  savedPromptId: number,
  tenantId: string,
  name?: string,
  content?: string
) {
  if (!name && !content) {
    return;
  }
  const url = "/saved_prompts/update";
  return await apiClient.post(url, {
    id: savedPromptId,
    name: name,
    content: content,
    tenant_id: tenantId,
  });
}

export const updateSavedPromptOrder = async (
  tenantId: string,
  savedPromptId: number,
  newOrder: number
): Promise<void> => {
  const url = "/saved_prompts/order";
  const body = {
    tenant_id: tenantId,
    saved_prompt_id: savedPromptId,
    new_order: newOrder,
  };
  try {
    await apiClient.post(url, body);
  } catch (e) {
    console.error(e);
  }
};

export async function getSavedPrompts(
  tenantId: string
): Promise<SavedPromptsList[]> {
  const url = "/saved_prompts?tenant_id=" + tenantId;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function getSavedPromptById(
  savedPromptId: number,
  tenantId: string
): Promise<SavedPrompt> {
  const url = `/saved_prompts/${savedPromptId}?tenant_id=${tenantId}`;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function deleteSavedPrompt(
  savedPromptId: number,
  tenantId: string
) {
  const url = "/saved_prompts";
  await apiClient.delete(url, {
    saved_prompt_id: savedPromptId,
    tenant_id: tenantId,
  });
}

marked.setOptions({
  gfm: true,
  breaks: true,
});

export const formatSavedPrompt = (content: string | undefined) => {
  if (!content) {
    return "";
  }
  const parsed = marked.parse(content);
  return (parsed as string).replace(
    /<a\s+(href="[^"]+")>/gi,
    '<a $1 target="_blank" rel="noopener noreferrer" class="text-blue-600 underline hover:text-primary">'
  );
};
