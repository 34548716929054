<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { computed } from "vue";
import { ChatSession } from "@/types";
import { useTenantStore } from "@/stores/tenant";
import { useChatSessionsStore } from "@/stores/chatSessions";

const props = defineProps<{
  isArchiveDialogOpen: boolean;
  chatSession: ChatSession | null;
}>();

const emit = defineEmits(["update:isArchiveDialogOpen"]);

const tenantStore = useTenantStore();
const chatSessionsStore = useChatSessionsStore();
const route = useRoute();
const router = useRouter();

function truncateString(input: string | undefined): string {
  if (!input) {
    return "";
  }
  return input.length > 25 ? input.substring(0, 25) + "..." : input;
}

const isArchiveDialogOpen = computed({
  get: () => props.isArchiveDialogOpen,
  set: (value: boolean) => emit("update:isArchiveDialogOpen", value),
});

const isLastSession = computed(() => {
  return chatSessionsStore.chatSessions.length === 1;
});

function hideDialog() {
  isArchiveDialogOpen.value = false;
}

async function handleArchiveSavedPrompt() {
  if (!props.chatSession || !tenantStore.tenantId) {
    return;
  }

  await chatSessionsStore.archiveChatSession(
    props.chatSession.id,
    tenantStore.tenantId
  );
  hideDialog();

  if (route.params.session_id === props.chatSession.id) {
    const lastSessionId = chatSessionsStore.chatSessions[0].id;
    router.replace({ name: "Chat", params: { session_id: lastSessionId } });
  }
}
</script>

<template>
  <AlertDialog v-model:open="isArchiveDialogOpen">
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          <template v-if="isLastSession">
            Cannot delete the last chat session
          </template>
          <template v-else>
            Delete Chat "{{ truncateString(props.chatSession?.name) }}"?
          </template>
        </AlertDialogTitle>
        <AlertDialogDescription v-if="isLastSession">
          Please create a new chat session before deleting this one.
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>
          <template v-if="isLastSession">OK</template>
          <template v-else>Cancel</template>
        </AlertDialogCancel>
        <Button
          v-if="!isLastSession"
          @click="handleArchiveSavedPrompt"
          variant="destructive"
          class="bg-red-400"
        >
          Delete
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
