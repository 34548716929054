import { useAuthStore } from "@/stores/auth";

async function getSessionToken() {
  const auth = useAuthStore();
  const token = await auth.getSessionToken();
  return token;
}

async function getIssuer() {
  const auth = useAuthStore();
  if (auth.isInsideTeams) {
    return "teams";
  }
  return "descope";
}

class ApiClient {
  host: string = import.meta.env.VITE_BACKEND_HOST;

  async fetchApi(url: string, options: Record<string, any>) {
    if (!this.host) {
      return await fetch(url, options);
    }

    if (url.startsWith("/")) {
      url = url.substring(1);
    }

    const response = await fetch(`https://${this.host}/${url}`, options);
    return response;
  }

  async get(url: string) {
    const sessionToken = await getSessionToken();
    const headers = {
      Authorization: `Bearer ${sessionToken}`,
      Issuer: await getIssuer(),
    };
    const options = {
      method: "GET",
      headers,
    };
    const response = await this.fetchApi(url, options);
    return response;
  }

  async post(url: string, body: Record<string, any>) {
    const sessionToken = await getSessionToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
      Issuer: await getIssuer(),
    };
    const options = {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    };
    const response = await this.fetchApi(url, options);
    const data = await response.json();
    return data;
  }

  async postFormData(url: string, formData: FormData) {
    const sessionToken = await getSessionToken();
    const headers = {
      Authorization: `Bearer ${sessionToken}`,
      Issuer: await getIssuer(),
    };
    const options = {
      method: "POST",
      headers,
      body: formData,
    };
    const response = await this.fetchApi(url, options);
    const data = await response.json();
    return data;
  }

  async delete(url: string, body: Record<string, any>) {
    const sessionToken = await getSessionToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
      Issuer: await getIssuer(),
    };
    const options = {
      method: "DELETE",
      headers,
      body: JSON.stringify(body),
    };
    const response = await this.fetchApi(url, options);
    const data = await response.json();
    return data;
  }

  async patch(url: string, body: Record<string, any>) {
    const sessionToken = await getSessionToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
      Issuer: await getIssuer(),
    };
    const options = {
      method: "PATCH",
      headers,
      body: JSON.stringify(body),
    };
    const response = await this.fetchApi(url, options);
    const data = await response.json();
    return data;
  }

  async getSharePointCredentials(tenantId: string) {
    const url = `/sharepoint/credentials?${"tenant_id"}=${encodeURIComponent(
      tenantId
    )}`;
    const response = await this.get(url);
    return await response.json();
  }

  async isSiteReadAll(tenantId: string) {
    const url = `/sharepoint/connection_type?${"tenant_id"}=${encodeURIComponent(
      tenantId
    )}`;
    const response = await this.get(url);
    const data = await response.json();
    return data.site_read_all;
  }
}

const apiClient = new ApiClient();
export default apiClient;
