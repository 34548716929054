<script setup lang="ts">
import { reactive } from "vue";
import { marked } from "marked";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetDescription,
} from "@/components/ui/sheet";
import Button from "@/components/ui/button/Button.vue";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Image, FileText, History, Loader2, Link } from "lucide-vue-next";
import { Sources, MessageImage, ImageMetadata } from "@/types";
import apiClient from "@/services/api_client";

type SourceDisplayProps = {
  sources: Sources;
  tenantId?: string;
};
const { sources, tenantId } = defineProps<SourceDisplayProps>();

const messageImages = reactive<{ [key: string]: MessageImage }>({});

async function fetchImage(imageMetadata: ImageMetadata) {
  if (!tenantId) return;

  const imageId = imageMetadata.image_id;

  const response = await apiClient.get(
    `/messages/images/${imageId}?tenant_id=${tenantId}`
  );
  const imageData = await response.json();
  if (!imageData) return;

  const messageImage: MessageImage = {
    image: imageData,
    metadata: imageMetadata,
  };

  messageImages[imageId] = messageImage;
}

async function fetchAllImages() {
  if (!sources) return;

  console.log(sources);

  if (!sources.images || sources.images.length <= 0) return;

  for (const imageMetadata of sources.images) {
    await fetchImage(imageMetadata);
  }
}
</script>

<template>
  <Sheet>
    <SheetTrigger @click="fetchAllImages"><slot></slot></SheetTrigger>
    <SheetContent class="min-w-[50%] flex flex-col gap-6">
      <SheetHeader class="flex-none">
        <SheetTitle class="text-2xl font-spartan"
          >Sources <span class="font-light">(Beta)</span></SheetTitle
        >
        <SheetDescription class="text-muted-foreground">
          These are all the sources Playground has access to generate the
          selected message. <br />
          Playground may determine that some sources are irrelevant and choose
          not to use them.
        </SheetDescription>
      </SheetHeader>
      <Tabs default-value="account" class="flex-1 flex flex-col overflow-auto">
        <TabsList
          class="grid w-full grid-flow-row grid-cols-[repeat(auto-fit,_minmax(100px,1fr))] auto-rows-auto gap-4 flex-none"
        >
          <TabsTrigger
            value="summaries"
            v-if="sources.summaries && sources.summaries.length > 0"
            ><div class="flex"><FileText class="w-4 h-4 mr-2" /> Summaries</div>
          </TabsTrigger>
          <TabsTrigger
            value="images"
            v-if="sources.images && sources.images.length > 0"
            ><div class="flex"><Image class="w-4 h-4 mr-2" /> Images</div>
          </TabsTrigger>
          <TabsTrigger
            value="documents"
            v-if="sources.documents && sources.documents.length > 0"
            ><div class="flex"><FileText class="w-4 h-4 mr-2" /> Documents</div>
          </TabsTrigger>
          <TabsTrigger value="memory" v-if="sources.memory"
            ><div class="flex"><History class="w-4 h-4 mr-2" /> Memory</div>
          </TabsTrigger>
        </TabsList>
        <div class="flex-1 overflow-auto">
          <!-- Summaries -->
          <TabsContent value="summaries" class="flex flex-col space-y-2">
            <div
              v-for="(summary, index) in sources.summaries"
              :key="index"
              class="flex-1"
            >
              <Card>
                <CardHeader>
                  <CardTitle class="text-lg"
                    >{{ summary.metadata.source || "Summary" }} ({{
                      index + 1
                    }}/{{ sources.summaries?.length }})</CardTitle
                  >
                </CardHeader>
                <CardContent class="text-sm">
                  <Button
                    v-if="summary.metadata.web_url"
                    variant="link"
                    class="pl-0"
                    ><Link class="w-4 h-4 mr-2" /><a
                      :href="summary.metadata.web_url"
                      target="_blank"
                      >Full document</a
                    ></Button
                  >
                  <div v-html="marked.parse(summary.content)"></div
                ></CardContent>
              </Card>
            </div>
          </TabsContent>
          <!-- Documents-->
          <TabsContent value="documents" class="flex flex-col space-y-2">
            <div
              v-for="(document, index) in sources.documents"
              :key="index"
              class="flex-1"
            >
              <Card>
                <CardHeader>
                  <CardTitle class="text-lg"
                    >{{ document.metadata.source || "Summary" }} ({{
                      index + 1
                    }}/{{ sources.documents?.length }})</CardTitle
                  >
                </CardHeader>
                <CardContent class="text-sm">
                  <Button
                    v-if="document.metadata.web_url"
                    variant="link"
                    class="pl-0"
                    ><Link class="w-4 h-4 mr-2" /><a
                      :href="document.metadata.web_url"
                      target="_blank"
                      >Full document</a
                    ></Button
                  >
                  <div v-html="marked.parse(document.content)"></div
                ></CardContent>
              </Card>
            </div>
          </TabsContent>
          <!-- Images -->
          <TabsContent value="images" class="flex flex-col space-y-2">
            <div
              v-for="(imageMetadata, index) in sources.images"
              class="flex-1"
            >
              <Card v-if="messageImages[imageMetadata.image_id]">
                <CardHeader>
                  <CardTitle class="text-lg"
                    >{{ imageMetadata.source }} ({{ index + 1 }}/{{
                      sources.images?.length
                    }})</CardTitle
                  >
                </CardHeader>
                <CardContent class="text-sm">
                  <Button
                    v-if="imageMetadata.web_url"
                    variant="link"
                    class="pl-0"
                    ><Link class="w-4 h-4 mr-2" /><a
                      :href="imageMetadata.web_url"
                      target="_blank"
                      >Source document</a
                    ></Button
                  >
                  <img
                    :src="
                      'data:image/jpeg;base64,' +
                      messageImages[imageMetadata.image_id].image
                    "
                    :alt="imageMetadata.source"
                /></CardContent>
              </Card>
              <div v-else class="flex">
                <Loader2 class="animate-spin h-5 w-5" /> Loading image
                {{ index + 1 }}
              </div>
            </div>
          </TabsContent>

          <TabsContent value="memory" class="flex flex-col space-y-2">
            <div class="text-sm" v-for="message in sources.memory">
              <Card>
                <CardHeader class="text-md">
                  {{ message.type === "human" ? "User" : "Playground" }}
                </CardHeader>
                <CardContent
                  class="text-sm"
                  v-html="marked.parse(message.content)"
                >
                </CardContent>
              </Card>
            </div>
          </TabsContent>
        </div>
      </Tabs>
    </SheetContent>
  </Sheet>
</template>
