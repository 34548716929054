<script setup lang="ts">
import { computed, inject, Ref } from "vue";
import Button from "@/components/ui/button/Button.vue";
import { MessageSquareQuote, Database, Bell } from "lucide-vue-next";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { Notification } from "@/types";
import { markNotificationsAsRead } from "@/services/notifications";
import { useTenantStore } from "@/stores/tenant";
import { cn } from "@/lib/utils";
import { useSidebar } from "@/components/ui/sidebar/utils";
import { useToolbarStore } from "@/stores/toolbarStore";

const tenantStore = useTenantStore();
const toolbarStore = useToolbarStore();
const notifications = inject<Ref<Notification[]>>("notifications");

const handleSelectMenu = (menu: string) => {
  if (toolbarStore.selectedMenu === menu) {
    toolbarStore.selectedMenu = "";
    toggleSidebar();
    return;
  }
  if (toolbarStore.selectedMenu === "") {
    toolbarStore.selectedMenu = menu;
    toggleSidebar();
  }
  toolbarStore.selectedMenu = menu;
};

const handleNotificationTriggerClick = async (menu: string) => {
  if (newNotification && tenantStore.tenantId) {
    const tenantId = tenantStore.tenantId;
    const unreadNotificationsIds = notifications!.value
      .filter((notification) => notification.unread)
      .map((notification) => notification.id);

    unreadNotificationsIds.forEach(async (id) => {
      try {
        await markNotificationsAsRead(tenantId, id);
      } catch (error) {
        console.error(error);
      }
    });
  }
  handleSelectMenu(menu);
};

const newNotification: Ref<boolean> = computed(() => {
  if (!notifications || notifications.value.length === 0) return false;
  return notifications.value.some((notification) => notification.unread);
});

const { toggleSidebar } = useSidebar();
</script>

<template>
  <div class="flex flex-col gap-4 py-6 px-2">
    <TooltipProvider :delayDuration="500">
      <Tooltip>
        <TooltipTrigger as-child>
          <Button
            data-sidebar="trigger"
            variant="ghost"
            size="icon"
            id="notifications-trigger"
            :class="
              cn(
                'relative rounded-full bg-primary30 hover:bg-primary40 p-1',
                toolbarStore.selectedMenu === 'notifications'
                  ? 'bg-foreground text-background hover:bg-foreground hover:text-background'
                  : 'text-foreground'
              )
            "
            @click="handleNotificationTriggerClick('notifications')"
          >
            <span
              v-if="newNotification"
              class="unread_messages_tag top-[calc(50%-.75em)] right-[calc(50%-.75em)] absolute rounded-full w-2 h-2 bg-destructive"
            ></span>
            <Bell class="w-4 h-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="left">
          <p>Notifications</p>
        </TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger as-child>
          <Button
            data-sidebar="trigger"
            variant="ghost"
            size="icon"
            id="prompts-trigger"
            :class="
              cn(
                'rounded-full bg-primary30 hover:bg-primary40 p-1',
                toolbarStore.selectedMenu === 'prompts'
                  ? 'bg-foreground text-background hover:bg-foreground hover:text-background'
                  : 'text-foreground'
              )
            "
            @click="handleSelectMenu('prompts')"
          >
            <MessageSquareQuote class="w-4 h-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="left">
          <p>Insert prompts</p>
        </TooltipContent>
      </Tooltip>
      <Tooltip v-if="!toolbarStore.hideDocumentButton">
        <TooltipTrigger as-child>
          <Button
            data-sidebar="trigger"
            variant="ghost"
            size="icon"
            id="documents-trigger"
            :class="
              cn(
                'rounded-full bg-primary30 hover:bg-primary40 p-1',
                toolbarStore.selectedMenu === 'documents'
                  ? 'bg-foreground text-background hover:bg-foreground hover:text-background'
                  : 'text-foreground'
              )
            "
            @click="handleSelectMenu('documents')"
          >
            <Database class="w-4 h-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="left">
          <p>Source files</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </div>
</template>
