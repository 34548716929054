import { defineStore } from "pinia";
import { ref, watch } from "vue";

import apiClient from "@/services/api_client";
import { useConfigCatStore } from "@/stores/configCat";
import { useUser } from "@descope/vue-sdk";

export const useUserStore = defineStore("user", () => {
  const configCatStore = useConfigCatStore();
  const { user: descopeUser } = useUser();
  const tenants = ref();
  const name = ref<string>();
  const email = ref<string>();
  const userId = ref<string>();

  watch(descopeUser, async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      configCatStore.fetchConfigCatFlags(newValue);
      if (newValue.name) {
        name.value = newValue.name;
      }
      if (newValue.email) {
        email.value = newValue.email;
      }
      if (newValue.userTenants) {
        tenants.value = newValue.userTenants;
      }

      if (newValue.userId) {
        userId.value = newValue.userId;
      }
    }
  });

  async function fetchTeamsUser() {
    const response = await apiClient.get("/teams/me");
    const result = await response.json();

    name.value = result.name;
    email.value = result.email;
    tenants.value = result.userTenants;
    userId.value = result.userId;
    configCatStore.fetchConfigCatFlags(result);
  }

  return {
    userId,
    tenants,
    name,
    email,
    fetchTeamsUser,
  };
});
