import { DataSource, Skills } from "@/types";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

export const useToolbarStore = defineStore("toolbar", () => {
  const selectedMenu = ref<string>("prompts");
  const selectedSkill = ref<Skills>();
  const selectedDataSources = ref<DataSource[] | null>(null);
  const showAttachedFiles = ref<boolean>(false);
  const reloadDatasourceDocuments = ref<boolean>(false);

  const setSelectedDatasources = (datasources: DataSource[]) => {
    selectedDataSources.value = datasources;
  };

  const hideDocumentButton = computed(() => {
    return (
      selectedSkill.value === Skills.CHAT_WITH_LLM ||
      selectedSkill.value === Skills.CHAT_WITH_WEB
    );
  });

  watch(
    () => hideDocumentButton.value,
    (newValue, oldValue) => {
      if (newValue && newValue !== oldValue && newValue === true) {
        selectedMenu.value = "prompts";
      }
    },
    { deep: true }
  );

  return {
    selectedMenu,
    selectedSkill,
    selectedDataSources,
    setSelectedDatasources,
    reloadDatasourceDocuments,
    hideDocumentButton,
    showAttachedFiles,
  };
});
