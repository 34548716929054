<script setup lang="ts" generic="TData">
import { type Table } from "@tanstack/vue-table";
import { ChevronLeft } from "lucide-vue-next";
import { ChevronRight } from "lucide-vue-next";
import { ChevronsLeft } from "lucide-vue-next";
import { ChevronsRight } from "lucide-vue-next";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface DataTablePaginationProps {
  table: Table<TData>;
  loading: boolean;
}
defineProps<DataTablePaginationProps>();

const handlePageSizeChange = (value: string, table: Table<TData>) => {
  const pageSize = parseInt(value, 10);
  table.setPageSize(pageSize);
};
</script>

<template>
  <div
    class="datatable_pagination flex items-center justify-between py-3"
    :class="{ 'opacity-50': loading }"
  >
    <div class="rows_per_page flex items-center space-x-2">
      <p class="text-sm font-medium">Rows per page</p>
      <Select
        :model-value="`${table.getState().pagination.pageSize}`"
        @update:model-value="(value) => handlePageSizeChange(value, table)"
      >
        <SelectTrigger class="h-8 w-[70px]">
          <SelectValue
            :placeholder="`${table.getState().pagination.pageSize}`"
          />
        </SelectTrigger>
        <SelectContent side="top">
          <SelectItem
            v-for="pageSize in [5, 10, 25, 50]"
            :key="pageSize"
            :value="`${pageSize}`"
          >
            {{ pageSize }}
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
    <div class="flex gap-4 justify-end items-center">
      <div class="flex w-fit items-center justify-center text-sm font-medium">
        Page {{ table.getState().pagination.pageIndex + 1 }} of
        {{ table.getPageCount() }}
      </div>
      <div class="flex items-center space-x-2">
        <Button
          variant="outline"
          class="w-8 h-8 p-0 lg:flex"
          :disabled="!table.getCanPreviousPage() || loading"
          @click="table.setPageIndex(0)"
        >
          <span class="sr-only">Go to first page</span>
          <ChevronsLeft class="w-4 h-4" />
        </Button>
        <Button
          variant="outline"
          class="w-8 h-8 p-0"
          :disabled="!table.getCanPreviousPage() || loading"
          @click="table.previousPage()"
        >
          <span class="sr-only">Go to previous page</span>
          <ChevronLeft class="w-4 h-4" />
        </Button>
        <Button
          variant="outline"
          class="w-8 h-8 p-0"
          :disabled="!table.getCanNextPage() || loading"
          @click="table.nextPage()"
        >
          <span class="sr-only">Go to next page</span>
          <ChevronRight class="w-4 h-4" />
        </Button>
        <Button
          variant="outline"
          class="w-8 h-8 p-0 lg:flex"
          :disabled="!table.getCanNextPage() || loading"
          @click="table.setPageIndex(table.getPageCount() - 1)"
        >
          <span class="sr-only">Go to last page</span>
          <ChevronsRight class="w-4 h-4" />
        </Button>
      </div>
    </div>
  </div>
</template>
