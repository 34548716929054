<script setup lang="ts">
import { useAuthStore } from "@/stores/auth";
import { Notification } from "@/types";
import { computed, inject } from "vue";
import { Loader2 } from "lucide-vue-next";
import { format } from "date-fns";

const notifications = inject<Notification[]>("notifications");
const loadingNotifications = inject<boolean>("loadingNotifications");
const auth = useAuthStore();

const loading = computed(() => {
  return auth.isLoading || loadingNotifications === true;
});

const formatDate = (date: Date) => {
  return format(date, "dd/MM/yyyy - HH:mm");
};
</script>

<template>
  <h3 class="font-medium mb-2">Notifications</h3>
  <ul class="notifications_list text-sm list-none p-0 flex flex-col gap-4">
    <Loader2 class="w-4 h-4 animate-spin" v-if="loading" />
    <li
      class="px-3 py-2 flex flex-col gap-1 rounded-md bg-primary10 cursor-pointer hover:bg-primary20 text-xs"
      v-for="notification in notifications"
      :key="notification.id"
    >
      <p class="notification_date text-muted-foreground truncate">
        {{ formatDate(notification.updated_at) }}
      </p>
      <p class="notification_message font-medium break-words">
        {{ notification.message }}
      </p>
    </li>
  </ul>
</template>
