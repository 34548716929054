<script setup lang="ts">
import { Button } from "@/components/ui/button";
import { useTenantStore } from "@/stores/tenant";
import { Trash2, Pencil, Eye } from "lucide-vue-next";
import { SavedPrompt } from "@/types";
import { ChevronLeft, ChevronRight } from "lucide-vue-next";
import { useUserStore } from "@/stores/user";

type Props = {
  savedPrompt: SavedPrompt;
};
const { savedPrompt } = defineProps<Props>();
const tenantStore = useTenantStore();
const { userId } = useUserStore();
const isAdmin = tenantStore.tenantAdmin;

const emit = defineEmits(["edit", "delete", "reorder"]);

const handleEdit = () => {
  emit("edit");
};
const handleDelete = () => {
  emit("delete");
};
const handleUpdateOrder = (upOrDown: "up" | "down") => {
  if (savedPrompt.order === null || savedPrompt.order === undefined) return;
  const newOrder =
    upOrDown === "up" ? savedPrompt.order + 1 : savedPrompt.order - 1;
  emit("reorder", { newOrder });
};
</script>

<template>
  <div
    class="prompt_card relative p-4 rounded-lg w-full h-48 flex flex-col gap-4 justify-between border border-primary30 overflow-hidden"
  >
    <div class="card_infos w-full flex flex-col gap-2">
      <div class="card_header w-full flex justify-between gap-4">
        <h2
          class="text-lg font-medium text-ellipsis overflow-hidden line-clamp-2"
        >
          {{ savedPrompt.name }}
        </h2>
        <p
          class="privacy_tag h-fit flex items-center justify-center px-2 py-1 rounded-full shrink-0 text-xs"
          :class="
            savedPrompt.visibility === 'public' ? 'bg-yellow25' : 'bg-primary30'
          "
        >
          {{ savedPrompt.visibility }}
        </p>
      </div>
      <div class="card_content">
        <p
          class="text-muted-foreground text-sm text-ellipsis overflow-hidden line-clamp-2"
        >
          {{ savedPrompt.content }}
        </p>
      </div>
    </div>

    <div class="card_actions flex items-center justify-between gap-4">
      <div class="edit-delete-btn flex items-center gap-4">
        <Button
          v-if="isAdmin || savedPrompt?.author === userId"
          @click="handleDelete"
          size="sm"
          variant="outline"
          class="flex gap-2"
          ><Trash2 class="w-4 h-4 text-forground"></Trash2> Delete</Button
        >
        <Button @click="handleEdit" size="sm" variant="dark" class="flex gap-2"
          ><Pencil
            v-if="isAdmin || savedPrompt?.author === userId"
            class="w-4 h-4 text-background"
          ></Pencil>
          <Eye v-else class="w-4 h-4 text-background"></Eye>
          {{ isAdmin || savedPrompt?.author === userId ? "Edit" : "Show" }}
        </Button>
      </div>
      <div class="order_buttons flex items-center gap-2">
        <Button
          variant="outline"
          class="p-0 aspect-square rounded-full h-6"
          @click="handleUpdateOrder('down')"
          ><ChevronLeft class="w-4 h-4"
        /></Button>
        <Button
          variant="outline"
          class="p-0 aspect-square rounded-full h-6"
          @click="handleUpdateOrder('up')"
          ><ChevronRight class="w-4 h-4"
        /></Button>
      </div>
    </div>
  </div>
</template>
