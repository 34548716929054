<script setup lang="ts">
import { Descope } from "@descope/vue-sdk";

import { useAuthStore } from "@/stores/auth";

const auth = useAuthStore();

const handleError = (e: any) => {
  console.log("Got error", e);
};

const handleSuccess = (e: any) => {
  console.log("Logged in", e);
};

const errorTransformer = (error: any) => {
  const translationMap = {
    SAMLStartFailed: "Failed to start SAML flow",
  };
  return (
    translationMap[error.type as keyof typeof translationMap] || error.text
  );
};
</script>

<template>
  <div class="wrapper">
    <p v-if="auth.isLoading">Loading...</p>
    <div v-else-if="auth.isAuthenticated">
      <h1>You are authenticated</h1>
    </div>
    <Descope
      v-else
      :flowId="'sign-up-or-in'"
      @error="handleError"
      @success="handleSuccess"
      :errorTransformer="errorTransformer"
    />
  </div>
</template>
