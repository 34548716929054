<script setup lang="ts">
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { onMounted, provide, ref, watch } from "vue";
import { useTenantStore } from "@/stores/tenant";
import { getNotifications } from "@/services/notifications";
import { Notification } from "@/types";
import ToolBarContent from "./ToolBarContent.vue";

const tenantStore = useTenantStore();
const selectedMenu = ref<string>("prompts");
const notifications = ref<Notification[]>([]);
const loadingNotifications = ref(true);

async function loadNotifications() {
  if (!tenantStore.tenantId) {
    return;
  }
  try {
    notifications.value = await getNotifications(tenantStore.tenantId);
  } catch (error) {
    console.error(error);
  } finally {
    loadingNotifications.value = false;
  }
}

onMounted(async () => {
  if (tenantStore.tenantId) {
    await loadNotifications();
  }
});

watch(
  () => tenantStore.tenantId,
  async (newTenantId) => {
    if (newTenantId) {
      loadingNotifications.value = true;
      await loadNotifications();
    }
  }
);
provide("notifications", notifications);
provide("loadingNotifications", loadingNotifications);
</script>
<template>
  <div class="bg-primary10">
    <SidebarProvider
      :style="{
        '--sidebar-width': '10rem',
        '--sidebar-width-mobile': '10rem',
      }"
    >
      <SidebarTrigger v-model="selectedMenu" />
      <ToolBarContent :selectedMenu="selectedMenu" />
    </SidebarProvider>
  </div>
</template>
