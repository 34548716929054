import apiClient from "@/services/api_client";

export const allowedExtensions = ["pdf", "docx", "doc", "pptx", "xlsx"];
export const acceptExtensionValue = allowedExtensions
  .map((ext) => `.${ext}`)
  .join(",");

export async function processFiles(
  files: File[],
  tenantId: string,
  toast: any,
  dataSourceId?: string,
  chatSessionId?: string | undefined
): Promise<void> {
  const validFiles = files.filter((file) => {
    const extension = file.name.split(".").pop()?.toLowerCase();
    return extension && allowedExtensions.includes(extension);
  });

  if (validFiles.length === 0) {
    toast.toast({
      title: "Invalid Files",
      description: "No valid files selected.",
      variant: "destructive",
      duration: 3000,
    });
    return;
  }

  const totalFiles = validFiles.length;
  const successfulFiles: string[] = [];

  const toasted = toast.toast({
    title: `Uploading document 1/${totalFiles}`,
    description: `${validFiles[0].name} - Progress: 0%`,
    duration: Infinity,
  });

  for (let index = 0; index < validFiles.length; index++) {
    const file = validFiles[index];

    try {
      await uploadFile(
        file,
        tenantId,
        dataSourceId,
        chatSessionId,
        (progress) => {
          toasted.update({
            id: toasted.id,
            title: `Uploading document ${index + 1}/${totalFiles}`,
            description: `${file.name} - Progress: ${progress}%`,
          });
        }
      );

      successfulFiles.push(file.name);
    } catch (error) {
      toasted.update({
        id: toasted.id,
        title: "Upload Error",
        description: `Failed to upload ${file.name}.`,
        variant: "destructive",
        duration: 3000,
      });
      console.error(`Error uploading file ${file.name}:`, error);
    }
  }

  try {
    if (successfulFiles.length > 0) {
      await indexFiles(
        successfulFiles,
        Number(dataSourceId),
        tenantId,
        chatSessionId
      );
      toasted.update({
        id: toasted.id,
        title: "Upload Complete",
        description: "All files uploaded and indexed successfully.",
        duration: 3000,
      });
    } else {
      toasted.update({
        id: toasted.id,
        title: "No Files Indexed",
        description: "No files were successfully uploaded.",
        variant: "destructive",
        duration: 3000,
      });
    }
  } catch (error) {
    toasted.update({
      id: toasted.id,
      title: "Indexing Error",
      description: "Failed to index files.",
      variant: "destructive",
      duration: 3000,
    });
    console.error("Error indexing files:", error);
  }
}

async function indexFiles(
  filesNames: string[],
  data_source_id: number,
  tenantId: string,
  chatSessionId?: string
): Promise<void> {
  const url = "/index";
  const response = await apiClient.post(url, {
    tenant_id: tenantId,
    data_source_id: data_source_id,
    files_names: filesNames,
    chat_session_id: chatSessionId,
  });
  return await response;
}

async function uploadFile(
  file: File,
  tenantId: string,
  dataSourceId?: string,
  chatSessionId?: string,
  onProgress?: (progress: number) => void
): Promise<boolean> {
  const chunkSize = 1 * 1024 * 1024; // 1MB
  const totalChunks = Math.ceil(file.size / chunkSize);
  let offset = 0;
  let chunkNumber = 0;
  let filePath = undefined;

  while (offset < file.size) {
    const chunk = file.slice(offset, offset + chunkSize);

    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("name", file.name);
    formData.append("chunk_number", String(chunkNumber));
    formData.append("total_chunks", String(totalChunks));
    formData.append("tenant_id", tenantId.toString());
    formData.append("data_source_id", dataSourceId ?? "-1");
    formData.append("file_path", filePath);
    formData.append("chat_session_id", chatSessionId ?? "");

    try {
      const response = await apiClient.postFormData("/uploads", formData);
      filePath = response.file_path;
    } catch (error) {
      console.error("Error uploading chunk:", error);
      return false;
    }

    offset += chunkSize;
    chunkNumber += 1;
    const progress = Math.floor((offset / file.size) * 100);
    if (onProgress) {
      onProgress(Math.min(progress, 100));
    }

    // Yield to the event loop
    await new Promise((resolve) => setTimeout(resolve, 0));
  }

  return true;
}

export async function updateDataSourceItem(
  dataSourceItemId: string,
  active: Boolean,
  tenantId: string
) {
  const url = "/datasource_item";
  const response = await apiClient.post(url, {
    tenant_id: tenantId,
    data_source_item_id: dataSourceItemId,
    active: active,
  });
  return await response;
}
