<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useTenantStore } from "@/stores/tenant";
import { useToolbarStore } from "@/stores/toolbarStore";
import { getChatSessionDocuments } from "@/services/data_source";
import Separator from "@/components/ui/separator/Separator.vue";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Loader2 } from "lucide-vue-next";
import { Item } from "@/types";
import { useRoute } from "vue-router";
import { updateDataSourceItem } from "@/services/upload_document";

const tenantStore = useTenantStore();
const toolbarStore = useToolbarStore();

const route = useRoute();
const chatSessionId = ref(route.params.session_id as string | undefined);

const datasourceDocuments = ref<Item[]>([]);
const datasourceDocumentsCount = ref<number | null>(null);
const loading = ref<boolean>(false);

const selectedOptions = ref<{ [key: string]: boolean }>({});
const pagination = ref({ page: 1, pageSize: 10 });

const showMoreButton = computed(() => {
  if (!datasourceDocumentsCount.value) return false;
  return (
    datasourceDocumentsCount.value >
    pagination.value.page * pagination.value.pageSize
  );
});

const loadDocuments = async (append = false) => {
  if (!tenantStore.tenantId || !chatSessionId.value) {
    return;
  }
  loading.value = true;
  try {
    if (!append) datasourceDocuments.value = [];
    const response = await getChatSessionDocuments({
      chatSessionId: chatSessionId.value || "",
      tenantId: tenantStore.tenantId,
      page: pagination.value.page,
      pageSize: pagination.value.pageSize,
    });
    datasourceDocumentsCount.value = response.count;
    datasourceDocuments.value = append
      ? [...datasourceDocuments.value, ...response.items]
      : response.items;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

const handleLoadMore = () => {
  pagination.value.page += 1;
  loadDocuments(true);
};

function initializeSelectedOptions() {
  selectedOptions.value = datasourceDocuments.value.reduce(
    (acc, document) => {
      if (document.datasource_item_id) {
        acc[document.datasource_item_id] = document.active || false;
      }
      return acc;
    },
    {} as { [key: string]: boolean }
  );
}

function handleChange(newChecked: boolean, dataSourceItemId: string) {
  selectedOptions.value[dataSourceItemId] = newChecked;
  updateDataSourceItem(
    dataSourceItemId,
    newChecked,
    tenantStore.tenantId || ""
  );
}

onMounted(async () => {
  chatSessionId.value = route.params.session_id as string;
  await loadDocuments();
  initializeSelectedOptions();
});

watch(
  datasourceDocuments,
  () => {
    initializeSelectedOptions();
  },
  { immediate: true } // Run on initial mount
);

watch(
  () => route.params.session_id,
  async (newSessionId) => {
    if (newSessionId) {
      chatSessionId.value = newSessionId as string;
      await loadDocuments();
    }
  }
);

watch(
  () => tenantStore.tenantId,
  async (newTenantId, oldTenantId) => {
    if (newTenantId !== oldTenantId) {
      await loadDocuments();
    }
  }
);

watch(
  () => toolbarStore.reloadDatasourceDocuments,
  async (reloadDocument) => {
    if (reloadDocument) {
      await loadDocuments();
      toolbarStore.reloadDatasourceDocuments = false;
    }
  }
);
</script>

<template>
  <Loader2
    class="w-4 h-4 animate-spin mt-2"
    v-if="loading && datasourceDocumentsCount === null"
  />
  <div
    v-if="datasourceDocumentsCount && datasourceDocumentsCount > 0"
    class="flex flex-col gap-3"
  >
    <div
      v-for="(item, index) in datasourceDocuments"
      :key="item.id"
      class="flex flex-col gap-3"
    >
      <div class="flex items-center gap-2 truncate text-muted-foreground">
        <Checkbox
          class="shrink-0 ![state=checked]:bg-foreground"
          :checked="selectedOptions[item.datasource_item_id || '']"
          @update:checked="
            (newChecked) =>
              handleChange(newChecked, item.datasource_item_id || '')
          "
        />
        <p class="truncate text-xs">{{ item.name }}</p>
      </div>
      <Separator v-if="index !== datasourceDocuments.length - 1" />
    </div>
    <Button
      @click="handleLoadMore"
      v-if="showMoreButton"
      variant="secondary"
      size="xs"
      class="w-fit mt-1 text-xs"
    >
      Show more
    </Button>
    <Loader2 class="w-4 h-4 animate-spin mt-2" v-if="loading" />
  </div>
  <p
    class="text-primary60 text-xs"
    v-if="!loading && datasourceDocumentsCount === 0"
  >
    No documents attached yet
  </p>
</template>
