<script setup lang="ts">
import { ref, computed } from "vue";
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
import validUrl from "valid-url";
import {
  Loader2,
  OctagonAlert,
  ListChecks,
  RotateCcw,
  Copy,
  Check,
} from "lucide-vue-next";

import Button from "@/components/ui/button/Button.vue";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import SourcesDisplay from "@/components/SourcesDisplay.vue";
import logo from "@/assets/logo-black.png";

const props = defineProps<{
  message?: string;
  type?: string;
  isLoading?: boolean;
  sources: any;
  tenantId?: string;
  displayLastBotButtons: boolean;
  index: number;
}>();

const messageContent = ref<HTMLElement | null>(null);
const isCopied = ref(false);

const copyButtonClasses = computed(() => {
  const baseClasses = [
    "h-fit",
    "aspect-square",
    "rounded-lg",
    "transition-colors",
    "duration-300",
  ];
  if (isCopied.value) {
    return [
      ...baseClasses,
      "bg-muted-foreground",
      "text-primary20",
      "hover:bg-muted-foreground",
    ];
  } else {
    return [
      ...baseClasses,
      "bg-primary20",
      "text-muted-foreground",
      "hover:bg-primary30",
    ];
  }
});

const messageClass = computed(() => {
  if (props.type === "user") {
    return "bg-primary20 p-4 rounded-lg border-0";
  }
  if (props.type === "bot" || props.type === "error") {
    return "flex gap-4";
  }
});

function isUrlValid(urlString: string): boolean {
  return !!validUrl.isUri(urlString);
}

function processMessage(message: string): string {
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  return message.replace(linkRegex, (match, p1, p2) => {
    if (isUrlValid(p2)) {
      return match;
    } else {
      return p1;
    }
  });
}

// Création d'un renderer personnalisé pour Marked
const renderer = new marked.Renderer();

// Pour les blocs de code, on utilise Highlight.js
renderer.code = ({ text, lang }) => {
  const { value, language } = hljs.highlightAuto(
    text,
    lang ? [lang] : undefined
  );
  console.log(language);
  return `<pre><code class="hljs ${language}">${value}</code></pre>`;
};

// Pour les liens, on ajoute les attributs cibles et classes
renderer.link = ({ href, title, text }) => {
  const isValid = href && validUrl.isUri(href);
  const safeHref = isValid ? href : "#";
  const linkTitle = title ? ` title="${title}"` : "";
  return `<a href="${safeHref}"${linkTitle} target="_blank" rel="noopener noreferrer" class="text-blue-600 underline hover:text-primary">${text}</a>`;
};

marked.setOptions({
  renderer,
  gfm: true,
  breaks: true,
  async: false,
});

const parsedMessage = computed(() => {
  const message = props.message || "";
  const processedMessage = processMessage(message);
  const parsed = marked.parse(processedMessage, { async: false }) as string;
  return parsed;
});

const showLoadingSpinner = computed(() => {
  return (
    props.isLoading ||
    (props.message && props.message.trim() === "...") ||
    (props.message && props.message.trim() === "")
  );
});

const handleMessageCopy = () => {
  if (messageContent.value) {
    const htmlContent = messageContent.value.innerHTML;
    const markdownContent = props.message || "";
    if (navigator.clipboard && navigator.clipboard.write) {
      const items = {
        "text/html": new Blob([htmlContent], { type: "text/html" }),
        "text/plain": new Blob([markdownContent], { type: "text/plain" }),
      };
      const clipboardItem = new ClipboardItem(items);
      navigator.clipboard
        .write([clipboardItem])
        .then(() => {
          isCopied.value = true;
          setTimeout(() => {
            isCopied.value = false;
          }, 2000); // Reset after 2 seconds
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      // Fallback pour les anciens navigateurs
      const textarea = document.createElement("textarea");
      textarea.value = markdownContent;
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand("copy");
        isCopied.value = true;
        setTimeout(() => {
          isCopied.value = false;
        }, 2000);
      } catch (err) {
        console.error("Fallback: Unable to copy", err);
      }
      document.body.removeChild(textarea);
    }
  }
};
</script>

<template>
  <div class="message_container my-4 max-w-2xl w-full break-words">
    <div class="message leading-7 text-sm" :class="messageClass">
      <Avatar
        class="playground_bot shrink-0 p-2 bg-background"
        v-if="props?.type === 'bot'"
      >
        <AvatarImage alt="Playground" :src="logo" />
        <AvatarFallback>PG</AvatarFallback>
      </Avatar>
      <div v-if="props?.type === 'error'" class="flex p-1">
        <OctagonAlert class="shrink-0 rounded-full w-7 h-7 bg-yellow-500 p-1" />
      </div>
      <div v-if="showLoadingSpinner" class="flex items-center space-x-2">
        <Loader2 class="animate-spin h-5 w-5" />
      </div>
      <div v-else class="message_content flex-1 w-full flex flex-col gap-3">
        <!-- Message content -->
        <div
          v-if="props.message"
          v-html="parsedMessage"
          ref="messageContent"
        ></div>
        <!-- Message toolbar -->
        <div
          v-if="props.type === 'bot' || props.type === 'error'"
          class="message_toolbar flex items-center space-x-2"
        >
          <SourcesDisplay :sources="props.sources" :tenantId="props.tenantId">
            <TooltipProvider v-if="props.type === 'bot' && props.sources">
              <Tooltip>
                <TooltipTrigger as-child>
                  <Button
                    class="h-fit aspect-square bg-primary20 text-muted-foreground hover:bg-primary30 rounded-lg"
                    size="xs"
                  >
                    <ListChecks :size="16" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>View sources</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </SourcesDisplay>

          <!-- Copy Button -->
          <TooltipProvider
            v-if="props?.type === 'bot' && props.displayLastBotButtons"
          >
            <Tooltip :open="isCopied ? true : undefined">
              <TooltipTrigger as-child>
                <Button
                  :class="copyButtonClasses"
                  size="xs"
                  @click="handleMessageCopy"
                >
                  <component :is="isCopied ? Check : Copy" :size="16" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {{ isCopied ? "Copied!" : "Copy answer" }}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <!-- Retry Button -->
          <TooltipProvider v-if="props.displayLastBotButtons">
            <Tooltip>
              <TooltipTrigger as-child>
                <Button
                  class="h-fit aspect-square bg-primary20 text-muted-foreground hover:bg-primary30 rounded-lg"
                  size="xs"
                  @click="$emit('retry', props.index)"
                >
                  <RotateCcw :size="16" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Try again</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  </div>
</template>
