import { OnboardingTour, OnboardingStatus, OnboardingType } from "@/types";
import apiClient from "./api_client";

type UpsertOnboardingParams ={
    tenantId:string;
    onboardingType:OnboardingType;
    status?:OnboardingStatus;
}

export async function getOnboardingTour(tenantId: string, onboardingType:OnboardingType): Promise<OnboardingTour> {
    const url = `/onboarding?tenant_id=${tenantId}&onboarding_type=${onboardingType}`;
    const response = await apiClient.get(url);
    return await response.json();
  }

export async function upsertOnboardingTour({tenantId, onboardingType, status}:UpsertOnboardingParams): Promise<OnboardingTour> {
    const url = '/onboarding';
    return await apiClient.post(url, {
        tenant_id: tenantId,
        onboarding_type: onboardingType,
        status,
    });
}

export const fetchOnboardingTourStatus = async(onboardingType: OnboardingType, tenantId?: string): Promise<boolean> => {
    if (!tenantId) {
      return false;
    }
    return await getOnboardingTour(tenantId, onboardingType).then(
      (response) => {
        if (!response) {
          upsertOnboardingTour({ tenantId, onboardingType });
          return true;
        } else if (response.status === "pending") {
          return true;
        } else {
          return false;
        }
      }
    );
}
