<script setup lang="ts">
import { computed, Ref, ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import { Input } from "@/components/ui/input";
import { Component, File, FolderClosed } from "lucide-vue-next";
import { Item, SearchItem } from "@/types";
import { useTenantStore } from "@/stores/tenant";
import apiClient from "@/services/api_client";
import SharepointSearchItemsList from "@/components/SharepointSearchItemsList.vue";
import { debounce } from "@/lib/utils";

const authStore = useAuthStore();
const tenantStore = useTenantStore();
const selectedTenant = ref<string>(tenantStore.tenantId || "");

const researchQuery = ref<string>("");
const itemsList = ref<SearchItem[]>([]);
const sitesList = ref<SearchItem[]>([]);
const loadingSearch = ref<boolean>(true);

const { token, openFolder } = defineProps<{
  token: string | undefined;
  openFolder: (item: Item, isSearch: boolean) => Promise<void>;
}>();
const folders: Ref<SearchItem[]> = computed(() =>
  itemsList.value.filter((item) => item.folder)
);
const files: Ref<SearchItem[]> = computed(() =>
  itemsList.value.filter((item) => !item.folder)
);

async function searchItems(researchQuery: string): Promise<void> {
  loadingSearch.value = true;

  if (
    !selectedTenant.value ||
    !token ||
    !researchQuery ||
    !researchQuery.trim()
  ) {
    loadingSearch.value = false;
    itemsList.value = [];
    return;
  }

  const from_teams = authStore.isInsideTeams;
  const searchUrl = "/sharepoint/search";
  const sitesSearchBody = {
    query: researchQuery,
    tenant_id: selectedTenant.value,
    access_token: token,
    from_teams: from_teams,
    entity_type: "site",
  };
  const itemsSearchBody = {
    query: researchQuery,
    tenant_id: selectedTenant.value,
    access_token: token,
    from_teams: from_teams,
  };

  try {
    const driveItems = await apiClient.post(searchUrl, itemsSearchBody);
    const sites = await apiClient.post(searchUrl, sitesSearchBody);
    itemsList.value = driveItems.items;
    sitesList.value = sites.items;
  } catch (error) {
    console.error(error);
  } finally {
    loadingSearch.value = false;
  }
}

const debouncedSearchItems = debounce(searchItems, 500);

const handleNavigate = (item: SearchItem) => {
  researchQuery.value = "";
  const drive_item_id = item.folder ? item.id : item.parentReference.id;
  openFolder(
    {
      id: item.id,
      name: item.name,
      item_type: "folder",
      drive_id: item.parentReference.driveId,
      drive_item_id:
        item.type !== "#microsoft.graph.site" ? drive_item_id : undefined,
      site_id: item.type === "#microsoft.graph.site" ? item.id : undefined,
    },
    true
  );
};
</script>

<template>
  <div class="search_bar w-full relative">
    <Input
      @keyup="debouncedSearchItems(researchQuery)"
      v-model="researchQuery"
      id="search"
      type="text"
      placeholder="Your search here ..."
      class="flex-1 h-8"
    />
    <div
      v-if="researchQuery.length > 0"
      class="suggestions_container p-4 rounded-lg absolute top-10 left-0 w-full bg-white shadow-lg z-10 max-h-72 overflow-y-auto"
    >
      <div v-if="loadingSearch">Loading...</div>
      <div
        v-else-if="
          !loadingSearch && itemsList.length === 0 && sitesList.length === 0
        "
      >
        No result found.
      </div>
      <div v-else class="search_results flex flex-col gap-4">
        <SharepointSearchItemsList
          :items_list="sitesList"
          label="Sites"
          :icon="Component"
          :handleNavigate="handleNavigate"
        />
        <SharepointSearchItemsList
          :items_list="folders"
          label="Folders"
          :icon="FolderClosed"
          :handleNavigate="handleNavigate"
        />
        <SharepointSearchItemsList
          :items_list="files"
          label="Files"
          :icon="File"
          :handleNavigate="handleNavigate"
        />
      </div>
    </div>
  </div>
</template>
