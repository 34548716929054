import apiClient from "@/services/api_client";
import { Notification } from "@/types";

export async function getNotifications(
  tenantId: string
): Promise<Notification[]> {
  const url = `/notifications?tenant_id=${tenantId}`;
  const response = await apiClient.get(url);
  return await response.json();
}

export async function markNotificationsAsRead(
  tenant_id: string,
  notificationId: string
): Promise<void> {
  if (!notificationId) {
    throw new Error("Invalid notification ID");
  }
  const url = "/notifications/update";
  const body = {
    tenant_id: tenant_id,
    notification_id: notificationId,
    unread: false,
  };
  await apiClient.post(url, body);
}
